import React from 'react';
import { Button, Card, Col, Form, Row, Select, Table, Typography } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import {EditOutlined} from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

const formInit = {
  _id: null,
  status: '',
};

const RefundForm = ({ data, onSubmit }) => {
  const selectedRefund = [data]
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const [currentIndex, setCurrentIndex] = useState(0);
  const onFinish = async (values) => {
    onSubmit(values);
  };

  let fullName = '';
  if (data.userId) {
    fullName += data.userId.firstName ? data.userId.firstName + ' ' : '';
    fullName += data.userId.lastName ? data.userId.lastName : '';
  }
  const refundStatus = ['Podnet', 'Primljen', 'Uspesno vracen'];
  const columns = [
    {
      key: '1',
      title: 'Broj povrata',
      dataIndex: 'refundNumber',
    },
    {
      key: '2',
      title: 'Broj porudžbine',
      dataIndex: 'code',
    },
    {
      key: '3',
      title: 'Status zahteva',
      dataIndex: 'status',
    },
    {
      key: '4',
      title: 'Kupac',
      render: () => {
				return(
					<div>
						{fullName}
					</div>
				)
			}
    },
    {
      key: '5',
      title: 'Komentar',
      dataIndex: 'comment',
    },
    {
      key: '6',
      title: 'Datum povrata',
      render: (record) => {
				return(

					<div>
						{moment(record.createdAt).format("DD.MM.YYYY")}
					</div>
				)
			}
    },
    {
      key: '7',
			title: 'Akcija',
			render: (text, record, index) => (
				<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px'}}>
					<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
						<a onClick={() => {setCurrentIndex(index);}}>
							<EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
						</a>
					</div>
				</div>)
    },
  ];

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
      <Table pagination={false} rowKey='_id' size="middle" style={{ margin: '0px 0px 40px 0px' }} columns={columns}  bordered  dataSource={selectedRefund}/>
      </div>
    </div>
  );
};

export default RefundForm;
