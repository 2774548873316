import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import ViewOrder from '../pages/orders/ViewOrders';
import Companies from '../pages/company/Company';
import EditCompany from '../pages/company/EditCompany';
import AddCompanyBranch from '../pages/company/AddCompanyBranch';
import Reclamations from '../pages/reclamations/Reclamations';
import Refunds from '../pages/refund/Refunds';
import EditReclamation from '../pages/reclamations/EditReclamation';
import EditRefund from '../pages/refund/EditRefund';

const routes = [
  {
    label: 'NOVE - VP',
    icon: 'ri-file-info-line',
    children: [
      {
        label: 'Nove porudžbenice VP',
        path: '/admin/new-orders-vp',
        component: Orders,
        value: 'vp new',
        allowed: ['admin', 'commercialist', 'user'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-order/:orderId',
        value: 'vp',
        component: EditOrder,
        allowed: ['admin', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'View order',
        path: '/admin/view-order/:orderId',
        value: 'vp',
        component: ViewOrder,
        allowed: ['admin', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'ODRAĐENE - VP',
    icon: 'ri-money-dollar-box-line',
    children: [
      {
        label: 'Odrađene porudžbenice VP',
        path: '/admin/finished-orders-vp',
        component: Orders,
        value: 'vp done',
        allowed: ['admin', 'commercialist', 'user'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'VP KUPCI',
    icon: 'ri-window-fill',
    children: [
      {
        label: 'Svi veleprodajni kupci',
        path: '/admin/vp-users',
        component: Users,
        value: 'vp',
        allowed: ['admin', 'commercialist'],
        showInMenu: true,
      },
      {
        label: 'Izmena korisnika',
        path: '/admin/edit-user/:userId',
        component: ViewUser,
        allowed: ['admin', 'commercialist', 'companyAdmin', 'company', 'wholesale user'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'commercialist', 'companyAdmin', 'company', 'wholesale user'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'REKLAMACIJE',
  //   icon: 'ri-ball-pen-fill',
  //   children: [
  //     {
  //       label: 'Sve reklamacije',
  //       path: '/admin/reclamations',
  //       component: Reclamations,
  //       value: 'vp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit order',
  //       path: '/admin/reclamations/:reclamationId',
  //       component: EditReclamation,
  //       value: 'vp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'View order',
  //       path: '/admin/view-order/:orderId',
  //       component: ViewOrder,
  //       value: 'vp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'POVRATI',
  //   icon: 'ri-magic-line',
  //   children: [
  //     {
  //       label: 'Svi povrati',
  //       path: '/admin/refunds',
  //       component: Refunds,
  //       value: 'vp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: true,
  //     },

  //   ],
  // },
];

export default routes;
