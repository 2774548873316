import React, { useEffect, useState, useContext } from 'react';
import { notification, Form, Input, Button, Image, Modal, Select, Table, Tooltip, Popconfirm } from 'antd';
import { FolderOpenOutlined, DeleteOutlined, MenuOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const initialForm = {
  title: null,
  image: null,
};

const EditDashHomeBanners = () => {
  const sessionStorageItems = sessionStorage.getItem('dash_banner_items')
    ? JSON.parse(sessionStorage.getItem('dash_banner_items'))
    : [];

  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [working, setWorking] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    article: null,
  });
  const [tableData, setTableData] = useState(sessionStorageItems);
  const { id } = useParams();

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        setWorking(true);
        if (id) {
          const bannerRes = await Axios.get(`${SERVER_URL}/dashboard-home-banners/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (bannerRes && bannerRes.data) {
            if (bannerRes.data.items) {
              setTableData(bannerRes.data.items);
              sessionStorage.setItem('dash_banner_items', JSON.stringify(bannerRes.data.items));
            }

            form.setFieldsValue(bannerRes.data);
            setImage(bannerRes.data.image);

            const selected = sessionStorage.getItem('selected_images_banner_dash');
            if (selected && selected !== 'undefined' && selected !== 'null') {
              const selectedVal = JSON.parse(selected);
              form.setFieldValue('image', selectedVal[0]);
              setImage(selectedVal[0]);
            }
          }
        } else {
          if (sessionStorage.getItem('dash_banner_title')) {
            form.setFieldValue('title', sessionStorage.getItem('dash_banner_title'));
          }

          const selected = sessionStorage.getItem('selected_images_banner_dash');
          if (selected && selected !== 'undefined' && selected !== 'null') {
            const selectedVal = JSON.parse(selected);
            form.setFieldValue('image', selectedVal[0]);
            setImage(selectedVal[0]);
          }
        }

        // fetch all articles for dropdown
        const allDataRes = await Axios.get(`${SERVER_URL}/articles-for-dropdown?vp=true`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (allDataRes && allDataRes.data) {
          setAllProducts(allDataRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem podataka.',
          placement: 'bottomRight',
        });
      }
    };
    fetchBanner();
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    if (values.image) {
      values.image = values.image._id;
    }
    if (tableData) {
      values.items = tableData;
    }

    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/dashboard-home-banners/${id}` : `${SERVER_URL}/dashboard-home-banners`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Uspeh',
        description: id ? 'Baner ažuriran' : 'Baner kreiran',
        placement: 'bottomRight',
      });

      sessionStorage.removeItem('dash_banner_title');
      sessionStorage.removeItem('dash_banner_items');
      sessionStorage.removeItem('selected_images_banner_dash');
      history.push('/admin/dashboard-home/banners');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Greška',
        description: id ? 'Problem sa ažuriranjem banera' : 'Problem sa kreacijom banera',
        placement: 'bottomRight',
      });
    }
  };

  const handleAddImages = () => {
    sessionStorage.removeItem('selected_images_banner_dash');
    sessionStorage.setItem('selected_images_banner_dash', JSON.stringify(image));
    history.push(
      !id ? `/admin/dashboard-home/banners-gallery` : `/admin/dashboard-home/banners-gallery?dashHomeId=${id}`,
    );
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const data = arrayMoveImmutable(tableData.slice(), oldIndex, newIndex).filter((el) => !!el);
      setTableData([...data]);
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = tableData.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const onOk = async () => {
    if (!modal.article) {
      notification.error({
        message: 'Izaberite proizvod iz padajuće liste',
        placement: 'bottomRight',
      });
      return;
    }

    const foundArticle = allProducts.find((item) => item._id === modal.article);
    setTableData([...tableData, foundArticle]);
    sessionStorage.setItem('dash_banner_items', JSON.stringify([...tableData, foundArticle]));

    notification.success({
      message: 'Artikal uspešno dodat na listu',
      placement: 'bottomRight',
    });
    setModal({ visible: false });
  };

  const columns = [
    {
      title: (
        <Tooltip title='Redosled ide od gore na dole i prevlacenjem se menja redosled'>
          <div style={{ display: 'flex' }}>
            SORT
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      ),
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'ŠIFRA',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'NAZIV ARTIKLA',
      dataIndex: ['title', 'en'],
      key: 'title',
    },
    {
      title: 'SLIKA',
      dataIndex: ['image', 'url'],
      key: 'image',
      align: 'center',
      render: (text, record) =>
        record?.image?.url ? (
          <Image src={record?.image?.url} alt='Banner image' style={{ height: '20px', width: '20px' }} />
        ) : (
          'N/A'
        ),
    },
    {
      title: 'AKCIJA',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Izbriši artikal sa liste'>
                <Popconfirm
                  title='Da li ste sigurni da hoćete da izbrišete ovaj artikal sa liste?'
                  onConfirm={() => {
                    setTableData(tableData.filter((item) => item._id !== record._id));
                    sessionStorage.setItem(
                      'dash_banner_items',
                      JSON.stringify(tableData.filter((item) => item._id !== record._id)),
                    );
                  }}
                  onCancel={() => console.log('Cancel')}
                  okText='Da'
                  okButtonProps={{ className: 'buttonCustom' }}
                  cancelText='Ne'
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className='table table-users' id='bannerIdEdit'>
      <div>
        <Button
          onClick={() => {
            sessionStorage.removeItem('selected_images_banner_dash');
            sessionStorage.removeItem('dash_banner_items');
            sessionStorage.removeItem('dash_banner_title');
            history.push(`/admin/dashboard-home/banners`);
          }}
          type='primary'
        >
          SVI BANERI
        </Button>
      </div>

      <br />
      <br />

      <div>
        <Form
          form={form}
          initialValues={initialForm}
          onFinish={async (values) => handleForm(values)}
          name='bannerFormDash'
        >
          <Form.Item
            name='title'
            label='Naziv'
            rules={[
              {
                required: true,
                message: 'Obavezno polje',
              },
            ]}
          >
            <Input onChange={(e) => sessionStorage.setItem('dash_banner_title', e.target.value)} />
          </Form.Item>

          <Form.Item name='image' label='Slika'>
            {image && <Image style={{ width: '200px', height: '200px' }} src={`${SERVER_URL}/${image.url}`} />}
            <div
              style={{
                width: '96px',
                height: '96px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '0.5px solid #7999bc',
                background: '#fff',
                borderRadius: '50%',
                cursor: 'pointer',
                margin: '0',
                color: '#A1A1A1',
              }}
              title='Dodaj sliku'
              onClick={handleAddImages}
            >
              <div>Izmeni sliku</div>
            </div>
          </Form.Item>

          <Form.Item>
            <Button onClick={() => setModal({ visible: true })} type='primary' style={{ marginBottom: '10px' }}>
              DODAJ ARTIKAL
            </Button>
            <Table
              size='small'
              bordered
              columns={columns}
              dataSource={tableData}
              rowKey={(record) => record._id}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              <FolderOpenOutlined style={{ marginRight: '5px' }} />
              SAČUVAJ
            </Button>
          </Form.Item>
        </Form>
      </div>

      <Modal
        centered
        width='60%'
        open={modal.visible}
        title='Dodaj artikal'
        okText='DODAJ'
        cancelText='OTKAŽI'
        maskClosable={false}
        onOk={onOk}
        onCancel={() => {
          setModal({ visible: false });
        }}
        confirmLoading={working}
        okButtonProps={{ style: { backgroundColor: '#f84804' } }}
        cancelButtonProps={{ style: { backgroundColor: '#000000' } }}
        getContainer={document.getElementById('bannerIdEdit')}
      >
        <Select
          allowClear
          showSearch
          value={modal.article}
          optionFilterProp='children'
          onChange={(value) => setModal({ ...modal, article: value })}
          style={{ width: '400px', fontSize: '13px' }}
          placeholder='Pretraži artikle...'
        >
          {allProducts.map((prod, index) => (
            <Select.Option
              key={`${prod._id}_${index}`}
              value={prod._id}
              title={`${prod.title.en} - ${prod.code}`}
              style={{ fontSize: '13px' }}
            >
              {prod.title.en} - {prod.code}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default EditDashHomeBanners;
