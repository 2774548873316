import { Popconfirm, Spin, Table, notification, Input } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  SearchOutlined,
  FilterFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

function Reviews() {
  const [working, setWorking] = useState(false);
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setWorking(true);
      await axios
        .get(`${SERVER_URL}/evaluations-b2b`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setWorking(false);
            setData(res.data);
          }
        })
        .catch((err) => {
          setWorking(false);
          console.error(err);
        });
    };
    fetchData();
  }, [fetchTrigger]);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary' onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord =
        Array.isArray(dataIndex) && record[dataIndex[0]] ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await axios.delete(`${SERVER_URL}/evaluations-b2b/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);
      notification.success({
        message: 'Recenzija obrisana',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Greška pri brisanju recenzije',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: 'BR.REC.',
      dataIndex: ['reviewNumber'],
      key: 'reviewNumber',
      ...getColumnSearchProps(['reviewNumber']),
    },
    {
      title: 'STATUS',
      dataIndex: ['approved'],
      key: 'approved',
      filters: [
        {
          text: 'ODOBREN',
          value: 'ODOBREN',
        },
      ],
      onFilter: (value, record) => record.approved,
      filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? 'red' : 'white', fontSize: '1rem' }} />,
      render: (value) => (value ? 'ODOBREN' : 'NEODOBREN'),
    },
    {
      title: 'ŠIFRA',
      dataIndex: ['dataId', 'code'],
      key: 'code',
      ...getColumnSearchProps(['dataId', 'code']),
    },
    {
      title: 'ARTIKAL',
      dataIndex: ['dataId', 'title'],
      key: 'title',
      ...getColumnSearchProps(['dataId', 'title']),
    },
    {
      title: 'IME',
      dataIndex: 'nickname',
      key: 'nickname',
      ...getColumnSearchProps(['nickname']),
    },
    {
      title: 'EMAIL',
      dataIndex: ['userId', 'email'],
      key: 'email',
      ...getColumnSearchProps(['userId', 'email']),
    },
    {
      title: 'KREIRAN',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      render: (value) => new Date(value).toLocaleString('en-US'),
    },
    {
      title: 'AŽURIRAN',
      dataIndex: 'updatedAt',
      align: 'center',
      key: 'updatedAt',
      render: (value) => new Date(value).toLocaleString('en-US'),
    },
  ];

  columns.push({
    title: 'AKCIJA',
    key: 'action',
    align: 'center',
    render: (value, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={`/admin/edit-review/${record._id}`}>
              <EditOutlined
                className='icon-unlock'
                title={`Izmeni recenziju`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EditFilled
                className='icon-lock'
                title={`Izmeni recenziju`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        </div>
      );
    },
  });
  columns.push({
    title: 'BRISANJE',
    key: 'delete',
    align: 'center',
    render: (value, record) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Popconfirm
              placement='left'
              title={`Da li ste sigurni da hoćete da izbrišete ovu recenziju?`}
              onConfirm={() => handleDelete(record._id)}
              okText='U redu'
              cancelText='Poništi'
              cancelButtonProps={{ className: 'b2b-secondary' }}
              okButtonProps={{ className: 'b2b-primary' }}
            >
              <DeleteOutlined className='icon-unlock' title={`Obriši recenziju`} />
              <DeleteFilled className='icon-lock' title={`Obriši recenziju`} />
            </Popconfirm>
          </div>
        </div>
      );
    },
  });
  return (
    <div className='table table-users'>
      <Spin spinning={working} tip='Učitavanje...'>
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </Spin>
    </div>
  );
}

export default Reviews;
