import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Table, Tooltip, notification, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

function RetailStore() {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [data, setData] = useState([]);

  let searchInput;

  useEffect(() => {
    const fetchRetailStores = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/retail-stores`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (res && res.data) {
          setData(res.data);
        }
        setWorking(false);
        console.log(res.data);
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem maloprodajnih objekata.',
          placement: 'bottomRight',
        });
      }
    };
    fetchRetailStores();
  }, [triggerFetch, currentuser.data.token]);

  const handleDelete = async (id) => {
    try {
      setWorking(true);
      await Axios.delete(`${SERVER_URL}/retail-stores/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setTriggerFetch((prev) => !prev);
      setWorking(false);
      notification.success({
        message: 'Objekat izbrisan.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa brisanjem objekta.',
        placement: 'bottomRight',
      });
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary' onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'Naziv',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Adresa',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Postanski broj + Grad/Mesto',
      dataIndex: 'location',
      key: 'location',
      ...getColumnSearchProps('location'),
    },
    {
      title: 'Broj telefona',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Radno vreme',
      dataIndex: 'working_hours',
      key: 'working_hours',
      render: (text, record) => (
        <td class='ant-table-cell'>{record.working_hours ? record.working_hours.join('; ') : ''}</td>
      ),
    },
    {
      title: 'Akcija',
      key: 'action',
      render: (value, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={`/admin/edit-retail-store/${record._id}`}>
              <EditOutlined
                className='icon-unlock'
                title={`Izmeni kontakt`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EditFilled
                className='icon-lock'
                title={`Izmeni kontakt`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: 'Brisanje',
      key: 'delete',
      render: (value, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Popconfirm
              placement='left'
              title={`Da li ste sigurni da hoćete da izbrišete ovaj objekat?`}
              onConfirm={() => handleDelete(record._id)}
              okText='U redu'
              cancelText='Poništi'
              cancelButtonProps={{ className: 'b2b-secondary' }}
              okButtonProps={{ className: 'b2b-primary' }}
            >
              <DeleteOutlined className='icon-unlock' title={`Obriši kontakt`} />
              <DeleteFilled className='icon-lock' title={`Obriši kontakt`} />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className='table table-users'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div>
          <Button className='contact-btn' onClick={() => history.push('/admin/new-retail-store')} type='primary'>
            <i class='ri-pencil-line'></i>
            <span style={{ marginTop: '3px' }}>NOVI MALOPRODAJNI OBJEKAT</span>
          </Button>
        </div>

        <br></br>
        <br></br>

        <div className='contact-table'>
          <Table
            size='middle'
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default RetailStore;
