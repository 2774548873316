import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  notification,
  Tooltip,
  Button,
  Table,
  Popconfirm,
  Spin,
  Divider,
  Input,
  Modal,
  Select,
  InputNumber,
  Image,
} from 'antd';
import { DeleteOutlined, MenuOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const { TextArea } = Input;

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const GroupInput = React.memo(({ value, onChange }) => {
  return (
    <Input value={value} onChange={onChange} style={{ border: '1px solid', marginRight: '10px', width: '400px' }} />
  );
});

const Articles = () => {
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  // const [group1, setGroup1] = useState({ title: null, description: null, data: [], position: 1 });
  // const [group2, setGroup2] = useState({ title: null, description: null, data: [], position: 2 });
  // const [group3, setGroup3] = useState({ title: null, description: null, data: [], position: 3 });
  // const [group4, setGroup4] = useState({ title: null, description: null, data: [], position: 4 });

  const [title1, setTitle1] = useState(null);
  const [title2, setTitle2] = useState(null);
  const [title3, setTitle3] = useState(null);
  const [title4, setTitle4] = useState(null);

  const [description1, setDescription1] = useState(null);
  const [description2, setDescription2] = useState(null);
  const [description3, setDescription3] = useState(null);
  const [description4, setDescription4] = useState(null);

  const [position1, setPosition1] = useState(null);
  const [position2, setPosition2] = useState(null);
  const [position3, setPosition3] = useState(null);
  const [position4, setPosition4] = useState(null);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    article: null,
    group: null,
  });
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setWorking(true);

        const articleRes = await Axios.get(`${SERVER_URL}/home-articles`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (articleRes && articleRes.data) {
          const gr1 = articleRes.data.find((item) => item.group === 1);
          const gr2 = articleRes.data.find((item) => item.group === 2);
          const gr3 = articleRes.data.find((item) => item.group === 3);
          const gr4 = articleRes.data.find((item) => item.group === 4);

          setTitle1(gr1?.title);
          setTitle2(gr2?.title);
          setTitle3(gr3?.title);
          setTitle4(gr4?.title);

          setDescription1(gr1?.description);
          setDescription2(gr2?.description);
          setDescription3(gr3?.description);
          setDescription4(gr4?.description);

          setPosition1(gr1?.position || 1);
          setPosition2(gr2?.position || 2);
          setPosition3(gr3?.position || 3);
          setPosition4(gr4?.position || 4);

          setData1(gr1?.items || []);
          setData2(gr2?.items || []);
          setData3(gr3?.items || []);
          setData4(gr4?.items || []);
          // setGroup1({
          //   title: gr1?.title,
          //   description: gr1?.description,
          //   data: gr1?.items || [],
          //   position: gr1?.position || group1.position,
          // });
          // setGroup2({
          //   title: gr2?.title,
          //   description: gr2?.description,
          //   data: gr2?.items || [],
          //   position: gr2?.position || group2.position,
          // });
          // setGroup3({
          //   title: gr3?.title,
          //   description: gr3?.description,
          //   data: gr3?.items || [],
          //   position: gr3?.position || group3.position,
          // });
          // setGroup4({
          //   title: gr4?.title,
          //   description: gr4?.description,
          //   data: gr4?.items || [],
          //   position: gr4?.position || group4.position,
          // });
        }

        // fetch all articles for dropdown
        const allDataRes = await Axios.get(`${SERVER_URL}/articles-for-dropdown`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (allDataRes && allDataRes.data) {
          setAllProducts(allDataRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem podataka.',
          placement: 'bottomRight',
        });
      }
    };

    fetchArticles();
  }, [fetchTrigger, currentuser.data.token]);

  const handleSort = async (group, oldIndex, newIndex) => {
    try {
      setWorking(true);
      let data = [];
      if (group === 1) {
        data = arrayMoveImmutable(data1.slice(), oldIndex, newIndex).filter((el) => !!el);
        // setData1(data)
      } else if (group === 2) {
        data = arrayMoveImmutable(data2.slice(), oldIndex, newIndex).filter((el) => !!el);
      } else if (group === 3) {
        data = arrayMoveImmutable(data3.slice(), oldIndex, newIndex).filter((el) => !!el);
      } else if (group === 4) {
        data = arrayMoveImmutable(data4.slice(), oldIndex, newIndex).filter((el) => !!el);
      }

      // send changed indexes to server
      await Axios.patch(
        `${SERVER_URL}/home-articles/${group}`,
        { items: data },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (err) {
      console.log(err);
      setWorking(false);
    }
    // UI sort
    // switch (group) {
    //   case 1:
    //     data = arrayMoveImmutable(data1.slice(), oldIndex, newIndex).filter((el) => !!el);
    //     // setGroup1({ ...group1, data });
    //     setData1(data);
    //     break;
    //   case 2:
    //     data = arrayMoveImmutable(data2.slice(), oldIndex, newIndex).filter((el) => !!el);
    //     // setGroup2({ ...group2, data });
    //     setData2(data);
    //     break;
    //   case 3:
    //     data = arrayMoveImmutable(data3.slice(), oldIndex, newIndex).filter((el) => !!el);
    //     // setGroup3({ ...group3, data });
    //     setData3(data);
    //     break;
    //   case 4:
    //     data = arrayMoveImmutable(data4.slice(), oldIndex, newIndex).filter((el) => !!el);
    //     // setGroup4({ ...group4, data });
    //     setData4(data);
    //     break;
    //   default:
    //     return;
    // }
  };

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/home-articles-items/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);

      notification.success({
        message: 'Artikal izbrisan iz liste.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem prilikom brisanja artikla iz liste.',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: (
        <Tooltip title='Redosled ide od gore na dole i prevlacenjem se menja redosled'>
          <div style={{ display: 'flex' }}>
            SORT
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      ),
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'NAZIV PROIZVODA',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'CENA',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
    },
    {
      title: 'SLIKA',
      dataIndex: ['featureImage', 'url'],
      key: 'image',
      align: 'center',
      render: (text, record) =>
        record?.featureImage?.url ? (
          <Image src={record?.featureImage?.url} alt='Article image' style={{ height: '20px', width: '20px' }} />
        ) : (
          'N/A'
        ),
    },
    {
      title: 'AKCIJA',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Izbriši artikal sa liste'>
                <Popconfirm
                  title='Da li ste sigurni da hoćete da izbrišete ovaj artikal sa liste?'
                  onConfirm={async () => handleDelete(record._id)}
                  onCancel={() => console.log('Cancel')}
                  okText='Da'
                  okButtonProps={{ className: 'buttonCustom' }}
                  cancelText='Ne'
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  // GROUP 1
  const onSortEnd1 = async ({ oldIndex, newIndex }) => {
    try {
      // setWorking(true);
      if (oldIndex !== newIndex) {
        await handleSort(1, oldIndex, newIndex);
      }
      // setWorking(false);
      // setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda artikala',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer1 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd1} {...props} />
  );
  const DraggableBodyRow1 = ({ className, style, ...restProps }) => {
    const index = data1.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // GROUP 2
  const onSortEnd2 = async ({ oldIndex, newIndex }) => {
    try {
      // setWorking(true);
      if (oldIndex !== newIndex) {
        await handleSort(2, oldIndex, newIndex);
      }
      // setWorking(false);
      // setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda artikala',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer2 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd2} {...props} />
  );
  const DraggableBodyRow2 = ({ className, style, ...restProps }) => {
    const index = data2.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // GROUP 3
  const onSortEnd3 = async ({ oldIndex, newIndex }) => {
    try {
      // setWorking(true);
      if (oldIndex !== newIndex) {
        await handleSort(3, oldIndex, newIndex);
      }
      // setWorking(false);
      // setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda artikala',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer3 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd3} {...props} />
  );
  const DraggableBodyRow3 = ({ className, style, ...restProps }) => {
    const index = data3.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // GROUP 4
  const onSortEnd4 = async ({ oldIndex, newIndex }) => {
    try {
      // setWorking(true);
      if (oldIndex !== newIndex) {
        await handleSort(4, oldIndex, newIndex);
      }
      // setWorking(false);
      // setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda artikala',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer4 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd4} {...props} />
  );
  const DraggableBodyRow4 = ({ className, style, ...restProps }) => {
    const index = data4.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const handleTitleAndDesc = async (group) => {
    try {
      setWorking(true);
      const title = group === 1 ? title1 : group === 2 ? title2 : group === 3 ? title3 : title4;
      const description =
        group === 1 ? description1 : group === 2 ? description2 : group === 3 ? description3 : description4;

      await Axios.patch(
        `${SERVER_URL}/home-articles/${group}`,
        { title, description, group },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Uspešno promenjen naziv liste artikala',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Problem sa ažuriranjem naslova',
        placement: 'bottomRight',
      });
    }
  };

  const handlePosition = async (group) => {
    try {
      setWorking(true);
      const position = group === 1 ? position1 : group === 2 ? position2 : group === 3 ? position3 : position4;

      await Axios.patch(
        `${SERVER_URL}/home-articles/${group}`,
        { position, group },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Uspešno promenjena pozicija liste',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Problem sa ažuriranjem pozicije liste',
        placement: 'bottomRight',
      });
    }
  };

  const handleModal = (group) => {
    setModal({ visible: true, group });
  };

  const onOk = async () => {
    if (!modal.article) {
      notification.error({
        message: 'Izaberite proizvod iz padajuće liste',
        placement: 'bottomRight',
      });
      return;
    }

    try {
      setWorking(true);

      await Axios.post(
        `${SERVER_URL}/home-articles-items?group=${modal.group}`,
        { item: modal.article },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Artikal uspešno dodat na listu',
        placement: 'bottomRight',
      });
      setModal({ visible: false });
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Problem sa dodavanjem artikla u listu',
        placement: 'bottomRight',
      });
    }
  };

  const tableProps = (groupData, onSortEndHandler, sortWrapper, sortRow) => ({
    dataSource: groupData,
    columns: columns,
    rowKey: '_id',
    pagination: false,
    components: {
      body: {
        wrapper: sortWrapper,
        row: sortRow,
      },
    },
    // onSortEnd: onSortEndHandler,
    // useDragHandle: true,
  });

  // const handleTitleChange1 = useCallback(
  //   (e) => {
  //     setGroup1((prevState) => ({ ...prevState, title: e.target.value }));
  //   },
  //   [setGroup1],
  // );
  // const handleTitleChange2 = useCallback(
  //   (e) => {
  //     setGroup2((prevState) => ({ ...prevState, title: e.target.value }));
  //   },
  //   [setGroup2],
  // );
  // const handleTitleChange3 = useCallback(
  //   (e) => {
  //     setGroup3((prevState) => ({ ...prevState, title: e.target.value }));
  //   },
  //   [setGroup3],
  // );
  // const handleTitleChange4 = useCallback(
  //   (e) => {
  //     setGroup4((prevState) => ({ ...prevState, title: e.target.value }));
  //   },
  //   [setGroup4],
  // );

  return (
    <div className='table table-users' id='articlePage'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div>
          <h3>1.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {/* <GroupInput value={group1.title} onChange={handleTitleChange1} /> */}
          <Input
            value={title1}
            onChange={(e) => setTitle1(e.target.value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitleAndDesc(1)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px', width: '537px' }}>
          <Tooltip title='Opis koji će da stoji odmah ispod naslova'>
            <TextArea
              value={description1}
              onChange={(e) => setDescription1(e.target.value)}
              style={{ border: '1px solid', marginRight: '10px' }}
              rows={2}
            />
          </Tooltip>
          <Button type='primary' onClick={async () => await handleTitleAndDesc(1)}>
            IZMENI OPIS
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <InputNumber
            value={position1}
            onChange={(value) => setPosition1(value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
            min={1}
            max={4}
          />
          <Button type='primary' onClick={async () => await handlePosition(1)}>
            IZMENI POZICIJU
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => handleModal(1)} type='primary'>
            DODAJ ARTIKAL
          </Button>
        </div>

        <br />
        <br />

        {/* GROUP 1 */}
        <div>
          <Table {...tableProps(data1, onSortEnd1, DraggableContainer1, DraggableBodyRow1)} />
        </div>

        <br></br>
        <br></br>
        <Divider />
        <br></br>
        <br></br>
        {/* GROUP 2 */}
        <div>
          <h3>2.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {/* <GroupInput value={group2.title} onChange={handleTitleChange2} /> */}
          <Input
            value={title2}
            onChange={(e) => setTitle2(e.target.value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitleAndDesc(2)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px', width: '537px' }}>
          <Tooltip title='Opis koji će da stoji odmah ispod naslova'>
            <TextArea
              value={description2}
              onChange={(e) => setDescription2(e.target.value)}
              style={{ border: '1px solid', marginRight: '10px' }}
              rows={2}
            />
          </Tooltip>
          <Button type='primary' onClick={async () => await handleTitleAndDesc(2)}>
            IZMENI OPIS
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <InputNumber
            value={position2}
            onChange={(value) => setPosition2(value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
            min={1}
            max={4}
          />
          <Button type='primary' onClick={async () => await handlePosition(2)}>
            IZMENI POZICIJU
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => handleModal(2)} type='primary'>
            DODAJ ARTIKAL
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Table {...tableProps(data2, onSortEnd2, DraggableContainer2, DraggableBodyRow2)} />
        </div>

        <br></br>
        <br></br>
        <Divider />
        <br></br>
        <br></br>
        {/* GROUP 3 */}
        <div>
          <h3>3.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {/* <GroupInput value={group3.title} onChange={handleTitleChange3} /> */}
          <Input
            value={title3}
            onChange={(e) => setTitle3(e.target.value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitleAndDesc(3)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px', width: '537px' }}>
          <Tooltip title='Opis koji će da stoji odmah ispod naslova'>
            <TextArea
              value={description3}
              onChange={(e) => setDescription3(e.target.value)}
              style={{ border: '1px solid', marginRight: '10px' }}
              rows={2}
            />
          </Tooltip>
          <Button type='primary' onClick={async () => await handleTitleAndDesc(3)}>
            IZMENI OPIS
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <InputNumber
            value={position3}
            onChange={(value) => setPosition3(value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
            min={1}
            max={4}
          />
          <Button type='primary' onClick={async () => await handlePosition(3)}>
            IZMENI POZICIJU
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => handleModal(3)} type='primary'>
            DODAJ ARTIKAL
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Table {...tableProps(data3, onSortEnd3, DraggableContainer3, DraggableBodyRow3)} />
        </div>

        <br></br>
        <br></br>
        <Divider />
        <br></br>
        <br></br>
        {/* GROUP 4 */}
        <div>
          <h3>4.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {/* <GroupInput value={group4.title} onChange={handleTitleChange4} /> */}
          <Input
            value={title4}
            onChange={(e) => setTitle4(e.target.value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitleAndDesc(4)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px', width: '537px' }}>
          <Tooltip title='Opis koji će da stoji odmah ispod naslova'>
            <TextArea
              value={description4}
              onChange={(e) => setDescription4(e.target.value)}
              style={{ border: '1px solid', marginRight: '10px' }}
              rows={2}
            />
          </Tooltip>
          <Button type='primary' onClick={async () => await handleTitleAndDesc(4)}>
            IZMENI OPIS
          </Button>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <InputNumber
            value={position4}
            onChange={(value) => setPosition4(value)}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
            min={1}
            max={4}
          />
          <Button type='primary' onClick={async () => await handlePosition(4)}>
            IZMENI POZICIJU
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => handleModal(4)} type='primary'>
            DODAJ ARTIKAL
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Table {...tableProps(data4, onSortEnd4, DraggableContainer4, DraggableBodyRow4)} />
        </div>

        <Modal
          centered
          width='60%'
          open={modal.visible}
          title='Dodaj artikal'
          okText='DODAJ'
          cancelText='OTKAŽI'
          maskClosable={false}
          onOk={onOk}
          onCancel={() => {
            setModal({ visible: false });
          }}
          confirmLoading={working}
          okButtonProps={{ style: { backgroundColor: '#f84804' } }}
          cancelButtonProps={{ style: { backgroundColor: '#000000' } }}
          getContainer={document.getElementById('articlePage')}
        >
          <Select
            allowClear
            showSearch
            value={modal.article}
            optionFilterProp='children'
            onChange={(value) => setModal({ ...modal, article: value })}
            style={{ width: '400px', fontSize: '13px' }}
            placeholder='Pretraži artikle...'
          >
            {allProducts.map((prod, index) => (
              <Select.Option
                key={`${prod._id}_${index}`}
                value={prod._id}
                title={`${prod.title.en} - ${prod.code}`}
                style={{ fontSize: '13px' }}
              >
                {prod.title.en} - {prod.code}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      </Spin>
    </div>
  );
};

export default Articles;
