import React, {useContext, useEffect} from 'react';
import {UserContext} from "../../App";
import useAxios from "../../hooks/useAxios";
import {SERVER_URL} from "../../config";
import {LoadingOutlined} from "@ant-design/icons";
import Table from "../../components/tables/RefundTable";
import moment from 'moment';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'refund', 'status', 'userId', 'orderId', 'orderNumber', 'user', 'currentAccount', 'updatedAt'];

const Refunds = () => {
	const currentuser = useContext(UserContext);
	const [refunds, fetchRefunds] = useAxios('', [], currentuser.data.token, 'get');

	useEffect(() => {
		fetchRefunds(`${SERVER_URL}/refunds`, []);
	}, [fetchRefunds]);

	let columnKeys;
	if (refunds.data.items && refunds.data.items.length > 0) {
		const keys = Object.keys(refunds.data.items[0]);
		columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
		columnKeys.splice(1, 0, 'orderNumber');
		columnKeys.splice(2, 0, 'status');
		columnKeys.splice(3, 0, 'user');

	}
	let tableData = [];
	if (refunds.data.items && refunds.data.items.length > 0) {
		tableData = refunds.data.items.map((item) => {
			item.createdAt = moment(item.createdAt).format("DD.MM.YYYY");
			item.updatedAt = moment(item.updatedAt).format("DD.MM.YYYY");
			item.orderNumber = item.orderId ? item.orderId.orderNumber : '';
			item.user = item.userId ? item.userId.firstName + ' ' + item.userId.lastName : '';
			return item;
		});
	}
	return (
		<>
			<div className='iconLabelContainer'>
        		<i className='ri-file-list-2-line'></i>
        		<div className='icon-label'>ZAHTEVI ZA POVRAT</div>
      		</div>
			<div className='table'>
				<div style={{ textAlign: 'center' }}>
					{refunds.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
					{!refunds.isLoading && refunds.data.items && refunds.data.items.length > 0 && (
						<Table data={tableData} columnKeys={columnKeys} title='Refund' editPath='/admin/edit-refunds/' />
					)}
					{!refunds.isLoading && refunds.data.items && refunds.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
				</div>
			</div>
		</>
	);
}

export default Refunds;
