import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { UserContext } from '../../App';
import { Form, notification, Input, Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FolderOpenOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import Axios from 'axios';

const initialForm = {
  name: null,
  address: null,
  location: null,
  phone: null,
  email: null,
};

function EditRetailStore() {
  const history = useHistory();
  const { id } = useParams();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [working_hours_1, setWorkingHours1] = useState(null);
  const [working_hours_2, setWorkingHours2] = useState(null);

  useEffect(() => {
    if (id) {
      const fetchRetailStore = async () => {
        try {
          const res = await Axios.get(`${SERVER_URL}/retail-stores/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          if (res && res.data) {
            form.setFieldsValue(res.data);
            if (res.data.working_hours && res.data.working_hours[0]) {
              setWorkingHours1(res.data.working_hours[0]);
            }
            if (res.data.working_hours && res.data.working_hours[1]) {
              setWorkingHours2(res.data.working_hours[1]);
            }
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Greška',
            description: 'Problem sa povlačenjem podataka.',
            placement: 'bottomRight',
          });
        }
      };
      fetchRetailStore();
    }
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    values.working_hours = [];
    if (working_hours_1) {
      values.working_hours = [...values.working_hours, working_hours_1];
    }
    if (working_hours_2) {
      values.working_hours = [...values.working_hours, working_hours_2];
    }

    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/retail-stores/${id}` : `${SERVER_URL}/retail-stores`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Success',
        description: id ? 'Objekat ažuriran' : 'Objekat kreiran',
        placement: 'bottomRight',
      });
      history.push('/admin/retail-stores');
    } catch (error) {
      notification.error({
        message: 'Greška',
        description: id
          ? 'Problem sa ažuriranjem maloprodajnih objekata'
          : 'Problem sa kreiranjem maloprodajnih objekata',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table table-users'>
      <div>
        <Button
          className='contact-btn'
          onClick={() => {
            history.push('/admin/retail-stores');
          }}
          type='primary'
        >
          <i class='ri-file-list-line'></i>
          <span style={{ marginTop: '3px' }}>SVI MP OBJEKTI</span>
        </Button>
      </div>

      <br />
      <br />

      <div>
        <Form
          form={form}
          initialValues={initialForm}
          onFinish={async (values) => handleForm(values)}
          name='retailStoresForm'
        >
          <div className='form-content'>
            <FormItem
              className='contact-item'
              name='name'
              label='Naziv'
              rules={[
                {
                  required: true,
                  message: 'Obavezno Polje',
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem className='contact-item' label='Adresa' name='address'>
              <Input />
            </FormItem>
            <FormItem className='contact-item' label='Postanski broj + Grad/Mesto' name='location'>
              <Input />
            </FormItem>
            <FormItem className='contact-item' label='Telefon' name='phone'>
              <Input type='number' />
            </FormItem>
            <FormItem className='contact-item' label='Email' name='email'>
              <Input type='email' />
            </FormItem>
            <FormItem className='contact-item' label='Radno vreme'>
              <Input value={working_hours_1} onChange={(e) => setWorkingHours1(e.target.value)} />
              <Input value={working_hours_2} onChange={(e) => setWorkingHours2(e.target.value)} />
            </FormItem>
          </div>
          <Form.Item style={{ marginTop: '30px' }}>
            <Button className='btn-contact' type='primary' htmlType='submit'>
              <FolderOpenOutlined style={{ marginRight: '8px' }} />
              {id ? 'SAČUVAJ' : 'KREIRAJ'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default EditRetailStore;
