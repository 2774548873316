import React from 'react';
import TopBarMenuItem from './TopBarMenuItem';

let topBarMenuItems = [];
const user = JSON.parse(sessionStorage.getItem('user'));
if (user && user.role === 'commercialist') {
  topBarMenuItems = [
    {
      icon: 'ri-ball-pen-fill',
      label: 'vp',
      link: '/admin',
    },
    // {
    //   icon: 'ri-ball-pen-fill',
    //   label: 'mp',
    //   link: '/admin'
    // },
    // {
    //   icon: 'ri-ball-pen-fill',
    //   label: 'administracija',
    // },
    // {
    //   icon: 'ri-ball-pen-fill',
    //   label: 'blog',
    // },
    // {
    //   icon: 'ri-ball-pen-fill',
    //   label: 'b2b panel',
    // },
  ];
} else {
  topBarMenuItems = [
    {
      icon: 'ri-ball-pen-fill',
      label: 'vp',
      link: '/admin',
    },
    {
      icon: 'ri-ball-pen-fill',
      label: 'mp',
      link: '/admin',
    },
    {
      icon: 'ri-ball-pen-fill',
      label: 'administracija',
      link: '/admin',
    },
    // {
    //   icon: 'ri-ball-pen-fill',
    //   label: 'blog',
    // },
    // {
    //   icon: 'ri-ball-pen-fill',
    //   label: 'b2b panel',
    // },
  ];
}

const TopBarMenu = (props) => {
  return (
    <>
      <div className='top-bar-menu-container'>
        {topBarMenuItems.map((item, index) => {
          return (
            <TopBarMenuItem key={index} topBarMenuItemProps={item} topBar={props.topBar} setTopBar={props.setTopBar} />
          );
        })}
      </div>

      <div
        onClick={() => props.setCollapsedNav(false)}
        className={`top-bar-menu-overlay ${props.collapsedNav ? 'isActive' : ''}`}
      ></div>

      <div className='masthead-top-bar-menu'></div>
    </>
  );
};

export default TopBarMenu;
