import React, { useState, useEffect, useContext } from 'react';
import { notification, Tooltip, Button, Table, Popconfirm, Spin, Image } from 'antd';
import { DeleteOutlined, MenuOutlined, InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useHistory } from 'react-router-dom';

const getUrl = (itemUrl) => {
  let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
  return url;
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DashHomeCatalogs = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);

        // catalogs
        const catalogueRes = await Axios.get(`${SERVER_URL}/dashboard-home?type=catalogs`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (catalogueRes && catalogueRes.data) {
          setCatalogs(catalogueRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem podataka.',
          placement: 'bottomRight',
        });
      }
    };

    fetchData();
  }, [fetchTrigger, currentuser.data.token]);

  const handleSort = async (array, oldIndex, newIndex) => {
    try {
      setWorking(true);
      const data = arrayMoveImmutable(array.slice(), oldIndex, newIndex).filter((el) => !!el);

      // send changed indexes to server
      await Axios.patch(`${SERVER_URL}/dashboard-home-catalogs-position`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Greška pri sortiranju kataloga',
        placement: 'bottomRight',
      });
      setWorking(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/dashboard-home-catalogs/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);

      notification.success({
        message: 'Katalog izbrisan iz liste.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem prilikom brisanja iz liste.',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: (
        <Tooltip title='Redosled ide od gore na dole i prevlacenjem se menja redosled'>
          <div style={{ display: 'flex' }}>
            SORT
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      ),
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'NAZIV KATEGORIJE',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'LINK',
      dataIndex: 'link',
      key: 'link',
      align: 'center',
      render: (text) => (
        <a href={text} target='_blank'>
          {text}
        </a>
      ),
    },
    {
      title: 'SLIKA',
      dataIndex: ['image', 'url'],
      key: 'image',
      align: 'center',
      render: (text, record) =>
        record?.image?.url ? (
          <Image src={getUrl(record?.image?.url)} alt='Catalogue image' style={{ height: '20px', width: '20px' }} />
        ) : (
          'N/A'
        ),
    },
    {
      title: 'AKCIJA',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Izmeni katalog sa liste'>
                <EditOutlined
                  onClick={() => history.push(`/admin/dashboard-home/catalogs/${record._id}`)}
                  style={{ marginRight: '10px' }}
                />
              </Tooltip>

              <Tooltip title='Izbriši katalog sa liste'>
                <Popconfirm
                  title='Da li ste sigurni da hoćete da izbrišete ovaj katalog sa liste?'
                  onConfirm={async () => handleDelete(record._id)}
                  onCancel={() => console.log('Cancel')}
                  okText='Da'
                  okButtonProps={{ className: 'buttonCustom' }}
                  cancelText='Ne'
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const tableProps = (groupData, onSortEndHandler, sortWrapper, sortRow) => ({
    dataSource: groupData,
    columns: columns,
    rowKey: '_id',
    pagination: false,
    components: {
      body: {
        wrapper: sortWrapper,
        row: sortRow,
      },
    },
  });

  const onSortEndCatalogs = async ({ oldIndex, newIndex }) => {
    try {
      if (oldIndex !== newIndex) {
        await handleSort(catalogs, oldIndex, newIndex);
      }
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda kataloga',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainerCat = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEndCatalogs} {...props} />
  );
  const DraggableBodyRowCat = ({ className, style, ...restProps }) => {
    const index = catalogs.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className='table table-users' id='homeDashboardCatalogs'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => history.push('/admin/dashboard-home/catalogs-new')} type='primary'>
            DODAJ KATALOG
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Table {...tableProps(catalogs, onSortEndCatalogs, DraggableContainerCat, DraggableBodyRowCat)} />
        </div>
      </Spin>
    </div>
  );
};

export default DashHomeCatalogs;
