import React from 'react';
import { Divider, Input, Form, Card } from 'antd';

const formInit = {
  companyName: undefined,
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  phone: undefined,
  taxId: undefined,
};

const CompanyForm = ({ isNew, data, onSubmit, language }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={isNew ? 'Dodaj kompaniju' : 'Izmeni kompaniju'} bordered={false}>
          <Form
            className='form-horizontal'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='vertical'
            form={form}
          >
            <Form.Item
              label='Naziv kompanije'
              name='companyName'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete naziv kompanije!',
                },
              ]}
            >
              <Input placeholder='Naziv kompanije' />
            </Form.Item>

            <Form.Item
              label='Matični broj'
              name='companyNumber'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete matični broj!',
                },
              ]}
            >
              <Input placeholder='Matični broj kompanije' />
            </Form.Item>

            <Form.Item
              label='Telefon'
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete broj telefona!',
                },
              ]}
            >
              <Input placeholder='Broj telefona' />
            </Form.Item>

            <Form.Item
              label='Adresa'
              name='address'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete adresu!',
                },
              ]}
            >
              <Input placeholder='Adresa kompanije' />
            </Form.Item>

            <Form.Item
              label='Grad'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete grad!',
                },
              ]}
            >
              <Input placeholder='Grad' />
            </Form.Item>

            <Form.Item
              label='Poštanski broj'
              name='zip'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete poštanski broj!',
                },
              ]}
            >
              <Input placeholder='Poštanski broj kompanije' />
            </Form.Item>

            <Form.Item
              label='Zemlja'
              name='country'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete zemlju!',
                },
              ]}
            >
              <Input placeholder='Zemlja' />
            </Form.Item>

            <Form.Item
              label='PIB'
              name='taxId'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete PIB!',
                },
              ]}
            >
              <Input placeholder='Poreski broj kompanije' />
            </Form.Item>

            <Form.Item label='Kontakt osoba' name='contactPerson'>
              <Input placeholder='Ime kontakt osobe' />
            </Form.Item>

            <Form.Item label='Diskont' name='discount'>
              <Input placeholder='Diskont' />
            </Form.Item>

            <Form.Item label='Komercijalista' name='commercialist'>
              <Input placeholder='Ime komercijaliste' />
            </Form.Item>

            <Form.Item label='Adresa za isporuku' name='deliveryAddress'>
              <Input placeholder='Adresa za isporuku' />
            </Form.Item>

            <Form.Item label='Uslovi plaćanja' name='termsOfPayment'>
              <Input placeholder='Uslovi plaćanja' />
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} kompaniju
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CompanyForm;
