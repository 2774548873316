import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Table, Tooltip, notification, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';


function Contacts() {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [data, setData] = useState([]);

  let searchInput;


  useEffect(() => {
    const fetchContacts = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/contact-data`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        if (res && res.data) {
          setData(res.data);
        }
        setWorking(false);
        console.log(res.data);

      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem podataka.',
          placement: 'bottomRight',

        });
      }
    };
    fetchContacts();

  }, [triggerFetch, currentuser.data.token]);

  const handleDelete = async (id) => {
    try {
      setWorking(true);
      await Axios.delete(`${SERVER_URL}/contact-data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      setTriggerFetch(prev => !prev);
      setWorking(false);
      notification.success({
        message: 'Kontakt izbrisan.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa povlačenjem podataka.',
        placement: 'bottomRight',
      });
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'Ime',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Prezime',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'Radno mesto',
      dataIndex: 'workplace',
      key: 'workplace',
      ...getColumnSearchProps('workplace')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email')
    },
    {
      title: 'Kreiran',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (value, record) => {
        const date = new Date(record.createdAt).toLocaleString();
        return (
          <span>{date}</span>
        );
      }
    },
    {
      title: 'Ažuriran',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      render: (value, record) => {
        const date = new Date(record.createdAt).toLocaleString();
        return (
          <span>{date}</span>
        );
      }
    },
    {
      title: 'Akcija',
      key: 'action',
      align: 'center',
      render: (value, record) => (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '15px',
        }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={`/admin/edit-contact/${record._id}`}>
              <EditOutlined
                className='icon-unlock'
                title={`Izmeni kontakt`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EditFilled
                className='icon-lock'
                title={`Izmeni kontakt`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: 'Brisanje',
      key: '',
      align: 'center',
      render: (value, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Popconfirm
              placement='left'
              title={`Da li ste sigurni da hoćete da izbrišete ovaj kontakt?`}
              onConfirm={() => handleDelete(record._id)}
              okText='U redu'
              cancelText='Poništi'
              cancelButtonProps={{ className: 'b2b-secondary' }}
              okButtonProps={{ className: 'b2b-primary' }}
            >
              <DeleteOutlined
                className='icon-unlock'
                title={`Obriši kontakt`}
              />
              <DeleteFilled
                className='icon-lock'
                title={`Obriši kontakt`}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    }
  ];

  return (
    <div className='table table-users'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div>
          <Button className='contact-btn' onClick={() => history.push('/admin/new-contact')} type='primary'>
            <i class='ri-pencil-line'></i>
            <span style={{ marginTop: '3px' }}>NOVI KONTAKT</span>
          </Button>
        </div>

        <br></br>
        <br></br>

        <div className='contact-table'>
          <Table
            size='middle'
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Contacts;
