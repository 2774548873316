import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import OrderFormMP from '../../components/forms/OrderFormMP';

const ViewOrder = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [order, setOrder] = useState({});
  const [reload, triggerReload] = useState(false);
  const [working, setWorking] = useState(false);
  const { orderId } = props.match.params;

  const fetchOrder = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/orders/${orderId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setOrder(res.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (orderId) fetchOrder();
  }, [orderId, reload]);

  const changeOrderItemStatus = async (record) => {
    try {
      await Axios.put(`${SERVER_URL}/orders/orderItem/${orderId}`, { specificItem: record._id });
      // setOrder(null);
      triggerReload((prev) => !prev);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async (formData) => {
    const method = 'put';
    const route = `${SERVER_URL}/orders/${orderId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Narudžba je izmenjena.`,
        placement: 'bottomRight',
      });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const createInvoice = async (orderData) => {
    const method = 'post';
    const route = `${SERVER_URL}/invoices`;
    try {
      await Axios[method](
        route,
        { orderId: orderId, email: order.data.user.email, dateOfPayment: order.data.createdAt, order: orderData },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Račun je kreiran.`,
        placement: 'bottomRight',
      });
      history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = order && Object.keys(order).length > 0 && currentuser.language;

  return (
    <div className='view-orders'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div className='iconLabelContainer'>
          <i className='ri-file-list-2-line'></i>
          <div className='icon-label'>
            {order && order.status === 'On hold' ? 'NOVA' : 'ODRAĐENA'} {props.topBar === 'vp' ? 'VP' : 'MP'} PORUDŽBINA
            #{order && order && order.orderNumber}
          </div>
        </div>
        <div className='table'>
          <div>
            {!isDataFetched && (
              <div style={{ textAlign: 'center' }}>
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              </div>
            )}
            {orderId && isDataFetched && (
              <OrderFormMP
                type={props.topBar}
                data={order}
                language={currentuser.language}
                onSubmit={onSubmit}
                token={currentuser.data.token}
                createInvoice={createInvoice}
                changeOrderItemStatus={changeOrderItemStatus}
                triggerReload={triggerReload}
                setWorking={setWorking}
              />
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ViewOrder;
