import React, { useState, useEffect, useContext } from 'react';
import { notification, Tooltip, Button, Table, Popconfirm, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, MenuOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const Slider = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [dataMob, setDataMob] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    const fetchSliders = async () => {
      try {
        setWorking(true);

        const sliderRes = await Axios.get(`${SERVER_URL}/sliders`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (sliderRes && sliderRes.data) {
          setData(sliderRes.data.filter((item) => !item.mobile));
          setDataMob(sliderRes.data.filter((item) => item.mobile));
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem podataka.',
          placement: 'bottomRight',
        });
      }
    };

    fetchSliders();
  }, [fetchTrigger, currentuser.data.token]);

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/slider/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);

      notification.success({
        message: 'Slajder izbrisan.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem prilikom brisanja slajdera.',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: (
        <Tooltip title='Redosled ide od gore na dole i prevlacenjem se menja redosled'>
          <div style={{ display: 'flex' }}>
            SORT
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      ),
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'NAZIV',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'KREIRAN',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    {
      title: 'AŽURIRAN',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
    {
      title: 'AKCIJA',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Ažuriraj slajder'>
                <EditOutlined
                  onClick={
                    // () => window.location.assign(`/admin/edit-slider/${record._id}`)
                    () => history.push(`/admin/edit-slider/${record._id}${record.mobile ? '?mobile=true' : ''}`)
                  }
                />
              </Tooltip>
            </div>

            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Izbriši slajder'>
                <Popconfirm
                  title='Da li ste sigurni da hoćete da izbrišete ovaj slajder?'
                  onConfirm={async () => handleDelete(record._id)}
                  onCancel={() => console.log('Cancel')}
                  okText='Da'
                  okButtonProps={{ className: 'buttonCustom' }}
                  cancelText='Ne'
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  // desktop
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    try {
      setWorking(true);
      if (oldIndex !== newIndex) {
        // UI sort
        const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex).filter((el) => !!el);
        setData(newData);

        // send changed indexes to server
        await Axios.patch(
          `${SERVER_URL}/sliders`,
          { data: newData },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda slajdera',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // mobile
  const onSortEndMob = async ({ oldIndex, newIndex }) => {
    try {
      setWorking(true);
      if (oldIndex !== newIndex) {
        // UI sort
        const newData = arrayMoveImmutable(dataMob.slice(), oldIndex, newIndex).filter((el) => !!el);
        setData(newData);

        // send changed indexes to server
        await Axios.patch(
          `${SERVER_URL}/sliders`,
          { data: newData },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda slajdera',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainerMob = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEndMob} {...props} />
  );
  const DraggableBodyRowMob = ({ className, style, ...restProps }) => {
    const index = dataMob.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className='table table-users'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => history.push(`/admin/new-slider`)} type='primary' style={{ marginRight: '20px' }}>
            NOVI SLAJDER
          </Button>

          <strong>
            <p>Desktop</p>
          </strong>
        </div>

        <br />
        <br />

        <div>
          <Table
            size='middle'
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            rowClassName={() => 'editable-row'}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* FOR MOBILE */}
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => history.push(`/admin/new-slider?mobile=true`)}
            type='primary'
            style={{ marginRight: '20px' }}
          >
            NOVI SLAJDER
          </Button>

          <strong>
            <p>Mob</p>
          </strong>
        </div>

        <br />
        <br />

        <div>
          <Table
            size='middle'
            bordered
            dataSource={dataMob}
            columns={columns}
            rowKey={(record) => record._id}
            rowClassName={() => 'editable-row'}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
            components={{
              body: {
                wrapper: DraggableContainerMob,
                row: DraggableBodyRowMob,
              },
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Slider;
