import React from 'react';
import { Link } from 'react-router-dom';

const TopBarMenuItem = ({ topBarMenuItemProps, topBar, setTopBar }) => {
  return (
    // <div className={`top-bar-menu-item-box ${topBar.toLowerCase() === topBarMenuItemProps.label.toLowerCase() ? 'selected-top-bar' : ''}`} onClick={() => setTopBar(topBarMenuItemProps.label)}>
    //   <i className={`navMenuIcon ${topBarMenuItemProps.icon}`}></i>
    //   {topBarMenuItemProps.label}
    // </div>
    <div className='top-bar-menu-item-box' onClick={() => setTopBar(topBarMenuItemProps.label)}>
      <Link to={topBarMenuItemProps.link} style={{ display: 'flex', alignItems: 'center' }}>
        <i className={`navMenuIcon ${topBarMenuItemProps.icon}`}></i>
        {topBarMenuItemProps.label}
      </Link>
    </div>
  );
};

export default TopBarMenuItem;
