import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { notification } from 'antd';
import CompanyForm from '../../components/forms/CompanyForm';

const EditCompany = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/companies/${id}`, {});
  }, [fetchData, id]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/companies` : `${SERVER_URL}/companies/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Kompanija je ${isNew ? 'kreirana.' : 'izmenjena.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/companies');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <button className='b2b-primary'>Sve kompanije</button>
        </Link>
      </div>
      <div>
        {!id && isDataFetched && (
          <CompanyForm
            isNew={true}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {id && !data.isError && data.data && isDataFetched && (
          <CompanyForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
