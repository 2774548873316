import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Layout, Tabs, notification, Table, Image, Spin, Input, Checkbox, Upload, message } from 'antd';
import { SearchOutlined, InboxOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Dragger } = Upload;

const getUrl = (itemUrl) => {
  let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
  return url;
};

const DashboardHomeBannerGallery = (props) => {
  const history = useHistory();
  const location = useLocation();
  const currentuser = useContext(UserContext);
  const [images, setImages] = useState([]);
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [selected, setSelected] = useState([]);

  const dashHomeId = location.search.includes('?dashHomeId=') ? location.search.split('=')[1] : null;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setWorking(true);
        const { data } = await Axios.get(`${SERVER_URL}/images`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data && data.length > 0) {
          setImages(data);
        }
        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem pri učitavanju slika iz galerije',
          placement: 'bottomRight',
        });
      }
    };

    fetchImages();
  }, [fetchTrigger, currentuser.data.token]);

  useEffect(() => {
    const currentImages = sessionStorage.getItem('selected_images_banner_dash');
    if (currentImages && currentImages !== 'undefined') {
      const selectedArr = JSON.parse(currentImages)?._id;
      if (selectedArr) setSelected([selectedArr]);
    }
  }, [currentuser.data.token]);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'black' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const insertHandler = () => {
    sessionStorage.removeItem('selected_images_banner_dash');
    let imagesToSend = [];
    if (selected.length > 0) {
      selected.forEach((el) => {
        const foundImage = images.find((image) => image._id === el);
        if (foundImage) {
          imagesToSend.push(foundImage);
        }
      });
    }
    sessionStorage.setItem('selected_images_banner_dash', JSON.stringify(imagesToSend));
    // window.location.assign(!dashHomeId ? '/admin/new-slider' : `/admin/edit-slider/${dashHomeId}`);
    history.push(!dashHomeId ? `/admin/dashboard-home/banners-new` : `/admin/dashboard-home/banners/${dashHomeId}`);
  };

  const selectHandler = (value) => {
    if (selected.includes(value)) {
      const removed = selected.filter((item) => item !== value);
      setSelected([...removed]);
    } else {
      setSelected([value]);
    }
  };

  const columns = [
    {
      title: 'SELECT',
      width: '4rem',
      dataIndex: '_id',
      filters: [
        {
          text: 'Selected',
          value: 'selected',
        },
      ],
      onFilter: (value, record) => selected.includes(record._id),
      render: (item, record, value) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Checkbox checked={selected.includes(record._id)} onChange={() => selectHandler(record._id)} />
          </div>
        );
      },
    },
    {
      key: 'file',
      title: 'FILE',
      render: (item) => (
        <div style={{ textAlign: 'center' }}>
          <Image src={getUrl(item.url)} alt={item.key} style={{ height: '50px', width: '50px' }} />
          {/* style={{ height: '50px', width: 'auto' }} */}
        </div>
      ),
    },
    { key: 'type', title: 'TYPE', dataIndex: 'type', ...getColumnSearchProps('type') },
    // { key: 'localPath', title: 'LOCAL PATH', dataIndex: 'localPath', ...getColumnSearchProps('localPath') },
    { key: 'originalname', title: 'ORIGINAL NAME', dataIndex: 'originalname', ...getColumnSearchProps('originalname') },
    {
      key: 'folder',
      title: 'FOLDER',
      dataIndex: ['folder', 'folderName'],
      ...getColumnSearchProps(['folder', 'folderName']),
    },
    {
      key: 'createdAt',
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    // { key: 'url', title: 'URL', dataIndex: 'url', render: (text, record) => `${SERVER_URL}${record.url}` },
  ];

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div className='actions-block'>
          <Button type='primary' onClick={insertHandler} style={{ marginRight: '10px' }}>
            Insert
          </Button>

          <Button
            type='primary'
            onClick={() => {
              sessionStorage.removeItem('selected_images_banner_dash');
              history.goBack();
            }}
          >
            Nazad
          </Button>
        </div>

        <div className='content'>
          <Layout className='site-layout-background'>
            <Content style={{ padding: '0 0 0 15px', minHeight: 280 }}>
              <Tabs defaultActiveKey='GALERIJA'>
                <TabPane tab='GALERIJA' key='GALERIJA'>
                  <div>
                    <Table
                      size='middle'
                      // rowSelection={{
                      //   selected,
                      //   onChange: (selectedRowKeys) => setSelected([...selectedRowKeys]),
                      // }}
                      bordered
                      columns={columns}
                      dataSource={images}
                      rowKey={(record) => record._id}
                    />
                  </div>
                </TabPane>
                <TabPane tab='UPLOAD' key='UPLOAD'>
                  <Dragger
                    style={{ width: '500px' }}
                    name='image'
                    multiple
                    action={`${SERVER_URL}/upload-local-image`}
                    accept='.jpg,.jpeg,.png,.bmp,.svg,.webp'
                    getValueFromEvent={normFile}
                    headers={{
                      // type: imageType,
                      // resize: true,
                      // height: imageHeight,
                      Authorization: `Bearer ${currentuser.data.token}`,
                      uri: 'public/images/',
                    }}
                    beforeUpload={(file) => {
                      const isAllowedFormat = ['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'].includes(
                        file.type,
                      );
                      if (!isAllowedFormat) {
                        notification.error({
                          message: 'Mogući formati za upload: JPG/PNG/SVG/WEBP!',
                          placement: 'bottomRight',
                        });
                      }
                      const isAllowedSize = file.size / 1024 / 1024 < 5;
                      if (!isAllowedSize) {
                        notification.error({
                          message: 'Slika mora biti manja od 5MB!',
                          placement: 'bottomRight',
                        });
                      }
                      return isAllowedFormat && isAllowedSize;
                    }}
                    onChange={async ({ file }) => {
                      if (file.status === 'done') {
                        if (file.response && file.response.file) {
                          message.success(`${file.response.file.name} fajl uspešno upload-ovan.`);
                          selectHandler(file.response.image._id);
                          setFetchTrigger(!fetchTrigger);
                        }
                      } else if (file.status === 'error') {
                        message.error(`Upload fajla neuspešan..`);
                      }
                    }}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Klikni ili prevuci ovde slike za dodavanje</p>
                  </Dragger>
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        </div>
      </Spin>
    </div>
  );
};

export default DashboardHomeBannerGallery;
