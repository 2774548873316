import React, { useEffect, useState, useContext } from 'react';
import { notification, Form, Input, Button, Tooltip, Image } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const initialForm = {
  title: null,
  link: null,
  image: null,
};

const EditBanner = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const location = useLocation();
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const group = location.search && location.search.includes('?group=') ? +location.search.split('=')[1] : null;

  useEffect(() => {
    if (id) {
      const fetchBanner = async () => {
        try {
          const bannerRes = await Axios.get(`${SERVER_URL}/home-banners-items/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (bannerRes && bannerRes.data) {
            form.setFieldsValue(bannerRes.data);
            const selected = sessionStorage.getItem('selected_images_slider');
            setImage(bannerRes.data.image);
            if (selected && selected !== 'undefined') {
              const selectedVal = JSON.parse(selected);
              form.setFieldValue('image', selectedVal);
              setImage(selectedVal);
            }
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Greška',
            description: 'Problem sa povlačenjem podataka.',
            placement: 'bottomRight',
          });
        }
      };

      fetchBanner();
    } else {
      const selected = sessionStorage.getItem('selected_images_slider');
      if (selected && selected !== 'undefined') {
        const selectedVal = JSON.parse(selected);
        form.setFieldValue('image', selectedVal);
        setImage(selectedVal);
      }
    }
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      const method = id ? 'patch' : 'post';
      const route = id
        ? `${SERVER_URL}/home-banners-items/${id}?group=${group}`
        : `${SERVER_URL}/home-banners-items?group=${group}`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Uspeh',
        description: id ? 'Baner ažuriran' : 'Baner kreiran',
        placement: 'bottomRight',
      });

      history.push('/admin/home/banners');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Greška',
        description: id ? 'Problem sa ažuriranjem banera' : 'Problem sa kreacijom banera',
        placement: 'bottomRight',
      });
    }
  };

  const handleAddImages = () => {
    sessionStorage.removeItem('selected_images_slider');
    sessionStorage.setItem('selected_images_slider', JSON.stringify(image));
    history.push(
      !id
        ? `/admin/banner-gallery${group ? '?group=' + group : ''}`
        : `/admin/banner-gallery?bannerId=${id}${group ? '&group=' + group : ''}`,
    );
  };

  return (
    <div className='table table-users'>
      <div>
        <Button
          onClick={() => {
            sessionStorage.removeItem('selected_images_slider');
            history.push(`/admin/home/banners`);
          }}
          type='primary'
        >
          SVI BANERI
        </Button>
      </div>

      <br />
      <br />

      <div>
        <Form form={form} initialValues={initialForm} onFinish={async (values) => handleForm(values)} name='bannerForm'>
          <Form.Item
            name='title'
            label='NASLOV'
            rules={[
              {
                required: true,
                message: 'Obavezno polje',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Tooltip title='Na koji link ce da vodi slika'>
            <Form.Item name='link' label='LINK'>
              <Input />
            </Form.Item>
          </Tooltip>

          <Form.Item name='image' label='Slika'>
            {image && <Image style={{ width: '200px', height: '200px' }} src={`${SERVER_URL}/${image.url}`} />}
            <div
              style={{
                width: '96px',
                height: '96px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '0.5px solid #7999bc',
                background: '#fff',
                borderRadius: '50%',
                cursor: 'pointer',
                margin: '0',
                color: '#A1A1A1',
              }}
              title='Dodaj sliku'
              onClick={handleAddImages}
            >
              <div>Izmeni sliku</div>
            </div>
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              <FolderOpenOutlined style={{ marginRight: '5px' }} />
              SAČUVAJ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditBanner;
