import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../config';
import { ROUTES_VP } from '../../configVP';
import { ROUTES_ADMIN } from '../../configAdmin/index';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Menu } from 'antd';
import Routes from '../routes/Routes';
const { Content, Sider } = Layout;


function NavMenuTablet(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const { setTopBar } = props;

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  let topBarMenuItems = [];
  const user = JSON.parse(sessionStorage.getItem('user'));
  if (user && user.role === 'commercialist') {
    topBarMenuItems = [
      {
        icon: 'ri-ball-pen-fill',
        label: 'vp',
        link: '/admin',
      },
    ];
  } else {
    topBarMenuItems = [
      {
        icon: 'ri-ball-pen-fill',
        label: 'vp',
        link: '/admin',
      },
      {
        icon: 'ri-ball-pen-fill',
        label: 'mp',
        link: '/admin',
      },
      {
        icon: 'ri-ball-pen-fill',
        label: 'administracija',
        link: '/admin',
      },
    ];
  }

  const menuLabelVp = topBarMenuItems[0]?.label;
  const menuIcon = topBarMenuItems[0]?.icon;
  const menuLabelMp = topBarMenuItems[1]?.label;
  const menuLabelAdmin = topBarMenuItems[2]?.label;

  const menuItems = [
    {
      label: menuLabelVp, key: menuLabelVp, icon: <i className={`navMenuIcon ${menuIcon}`}></i>, children: ROUTES_VP.map(route => {
        return {
          label: <Link to={route.children[0].path}>{route.label}</Link>,
          key: route.label,
        };
      })
    },
    {
      label: menuLabelMp, key: menuLabelMp, icon: <i className={`navMenuIcon ${menuIcon}`}></i>, children: ROUTES.map(route => {
        return {
          label: <Link to={route.children[0].path}>{route.label}</Link>,
          key: route.label,
        };
      })
    },
    {
      label: menuLabelAdmin, key: menuLabelAdmin, icon: <i className={`navMenuIcon ${menuIcon}`}></i>, children: ROUTES_ADMIN.map(route => {
        return {
          label: <Link to={`${route.children[0].path}`}>{route.label}</Link>,
          key: route.label,
        };
      })
    }
  ];

  return (
    <Layout
      style={{
        minHeight: '100vh',
        display: 'flex'
      }}
    >
      <Sider collapsible collapsed={collapsed} >
        {/* <Link to='/admin' className='logo-desktop'>
          <div className='logo'>
            <img src='/himtex-logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' />
          </div>
        </Link> */}
        <Link to='/admin' className='logo-tablet'>
          <div onClick={() => setBreadcrumb(null)} className='logo'>
            <img src='/himtex-logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' />
          </div>
        </Link>

        <div className="demo-logo-vertical" />
        <Button className='openSideBar'
          type="text"
          icon={collapsed ? <RightOutlined style={{ color: 'white' }} /> : <LeftOutlined style={{ color: 'white' }} />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
          }}
        />
        <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]} mode="inline" items={menuItems}
          onClick={({ keyPath, key }) => {
            setTopBar(keyPath[1]);
            console.log(key);
            setBreadcrumb(key);
          }}>
        </Menu>
        <div className='sider-profile-controls'>
          <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
        </div>
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            className='bread'
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>
              <Link to='/admin'>
                <HomeOutlined onClick={() => setBreadcrumb(null)} style={{ fontSize: '24px' }} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumbItem'>
              {breadcrumb}
            </Breadcrumb.Item>
          </Breadcrumb>

          <Routes topBar={props.topBar} />

        </Content>
      </Layout>
    </Layout >
  );
}

export default NavMenuTablet;
