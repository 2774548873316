import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button } from 'antd';
import { EditOutlined, SearchOutlined, EditFilled } from '@ant-design/icons';

const UsersTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, type, signUp }) => {
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary' onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    // setUserForEdit(ID);
    // setShowModal(!showModal);
  };
  const columns = [];

  if (type === 'vp') {
    columns.push(
      {
        key: 'companyName',
        title: 'Kompanija',
        dataIndex: 'companyName',
        ...getColumnSearchProps('companyName'),
      },
      {
        key: 'firstName',
        title: 'Ime',
        dataIndex: 'firstName',
        ...getColumnSearchProps('firstName'),
      },
      {
        key: 'lastName',
        title: 'Prezime',
        dataIndex: 'lastName',
        ...getColumnSearchProps('lastName'),
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        ...getColumnSearchProps('email'),
      },
      {
        key: 'commercialist',
        title: 'Komercijalista',
        dataIndex: 'commercialist',
        ...getColumnSearchProps('commercialist'),
      },
    );
  }
  if (type === 'mp') {
    columns.push(
      {
        key: 'firstName',
        title: 'Ime',
        dataIndex: 'firstName',
        ...getColumnSearchProps('firstName'),
      },
      {
        key: 'lastName',
        title: 'Prezime',
        dataIndex: 'lastName',
        ...getColumnSearchProps('lastName'),
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        ...getColumnSearchProps('email'),
      },
      {
        title: 'Kreiran',
        render: (text, record) => (
          <div style={{ fontSize: '15px' }}>{record.createdAt.split(',')[0].replaceAll('/', '.')}.</div>
        ),
      },
    );
  }

  columns.push({
    title: 'Administriranje korisnika',
    render: (text, record) => (
      <div style={{ fontSize: '15px', marginLeft: 'auto', marginRight: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EditOutlined
                className='icon-unlock'
                title={`Izmeni korisnika`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EditFilled
                className='icon-lock'
                title={`Izmeni korisnika`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
          <div style={{ fontSize: '15px', marginLeft: '15px' }}>
            <Button type='primary' className='buttonAction' onClick={() => signUp(record)}>
              Prijavi se
            </Button>
          </div>
        </div>
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default UsersTable;
