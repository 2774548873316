import React, { useState, useContext } from 'react';
import { Button, notification, Spin, Tooltip } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import { UserContext } from '../App';

const Home = ({ tab }) => {
  const [working, setWorking] = useState(false);
  const currentuser = useContext(UserContext);

  const handleDownload = async () => {
    try {
      setWorking(true);

      const file = await Axios.get(`${SERVER_URL}/download-products-admin`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([file.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Products_Admin.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setWorking(false);
      notification.success({
        message: 'Fajl skinut',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Greška',
        description: 'Pokušajte ponovo',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      {tab === 'administracija' && (
        <Spin spinning={working} tip='Učitavanje'>
          <Tooltip title='Fajl sa imenom proizvoda, šifrom i ključnim rečima'>
            <Button type='primary' onClick={handleDownload}>
              Download (proizvodi)
            </Button>
          </Tooltip>
        </Spin>
      )}
    </div>
  );
};

export default Home;
