import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link, useHistory } from 'react-router-dom';

const DataTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, increment, decrement, discount, removeProduct }) => {
  const history = useHistory();
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small' 
            style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  if (history.location.pathname.includes('/admin/edit-order/')) {
    columns.splice(1, 0, {
      title: 'category',
      render: record => <p>{record.category}</p>
    });
    columns.splice(2, 0, {
      title: 'discount',
      render: (record) => (
        <div style={{ margin: '0 auto', width: '60px', outline: '0', borderBottom: '1px solid black' }}>
          <Input
            bordered={false}
            placeholder='%'
            onChange={e => discount(e.currentTarget.value, record)}
            defaultValue={record.discountPercentage && record.discountPercentage}
          />
        </div>
      ),
    });
    columns.push({
      title: 'quantity',
      render: (record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'left', alignItems: 'center', fontSize: '15px' }}>
          <Button
            onClick={() => increment(record)}
            icon={<PlusOutlined />}
            style={{ border: '0', width: '15px', marginRight: '5px' }}
          />
          <span>{record.quantity}</span>
          <Button
            onClick={() => decrement(record)}
            icon={<MinusOutlined />}
            style={{ border: '0', width: '15px', marginLeft: '5px' }}
          />
        </div>
      ),
    });
    columns.push({
      title: 'Subtotal',
      render: (record) => (
        <span>{record.discountPercentage ? (record.quantity * (record.price - record.price * +record.discountPercentage / 100)).toFixed(2) : (record.quantity * record.price).toFixed(2)}</span>
      ),
    });
    columns.push({
      title: 'action',
      render: record => (
        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            placement='left'
            title={`Da li želite da uklonite proizvod?`}
            onConfirm={() => removeProduct(record._id)}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{ className: 'b2b-secondary' }}
            okButtonProps={{ className: 'b2b-primary' }}
          >
            <DeleteOutlined title={`Obriši proizvod`} />
          </Popconfirm>
        </div>
      )
    });
  } else {
    columns.push({
      title: 'action',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={editPath + record._id}>
              <EditOutlined
                className='icon-unlock'
                // title={`Edit ${title.toLowerCase()}`}
                title={`Izmena proizvoda`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EditFilled
                className='icon-lock'
                // title={`Edit ${title.toLowerCase()}`}
                title={`Izmena proizvoda`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>

          {viewPath && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
              <Link to={viewPath + record._id}>
                <EyeOutlined
                  className='icon-unlock'
                  title={`View ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
                <EyeOutlined
                  className='icon-lock'
                  title={`View ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
              </Link>
            </div>
          )}

          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              // title={`This will delete ${title.toLowerCase()}`}
              title={`Ovo će izbrisati proizvod`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='U redu'
              cancelText='Poništi'
              cancelButtonProps={{ className: 'b2b-secondary' }}
              okButtonProps={{ className: 'b2b-primary' }}
            >
              <DeleteOutlined className='icon-unlock'
                // title={`Delete ${title.toLowerCase()}`} 
                title={`Brisanje proizvoda`}
              />
              <DeleteFilled className='icon-lock'
                // title={`Delete ${title.toLowerCase()}`} 
                title={`Brisanje proizvoda`}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    });
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default DataTable;
