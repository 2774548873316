import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Popconfirm, notification, Spin, Rate } from 'antd';
import { DeleteOutlined, DownloadOutlined, FolderOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const { TextArea } = Input;

const initialForm = {
  productTitle: '',
  firstName: '',
  email: '',
  approved: false,
  review: '',
  answer: '',
  value: 0,
  code: '',
};

const EditReview = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/evaluations-b2b/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data) {
          form.setFieldsValue({
            productTitle: data?.dataId?.title,
            code: data?.dataId?.code,
            firstName: data.nickname ? data.nickname : data?.userId?.firstName,
            email: data?.userId?.email,
            review: data.review,
            answer: data.answer,
            approved: data.approved,
            value: data.value,
          });
          setIsApproved(data.approved ? true : false);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem sa učitavanjem recenzije',
          placement: 'bottomRight',
        });
      }
    };

    fetchReview();
  }, [fetchTrigger, currentuser.data.token]);

  const handleForm = async (values) => {
    const body = { answer: values.answer, review: values.review };

    try {
      setWorking(true);

      await Axios.patch(`${SERVER_URL}/evaluations-b2b/${id}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);
      notification.success({
        message: 'Recenzija ažurirana',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška pri ažuriranju recenzije',
        placement: 'bottomRight',
      });
    }
  };

  const handleApprove = async () => {
    try {
      setWorking(true);

      const changeApproveStatus = form.getFieldValue('approved') ? false : true;

      await Axios.patch(
        `${SERVER_URL}/evaluations-b2b/${id}`,
        { approved: changeApproveStatus },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setFetchTrigger(!fetchTrigger);
      setWorking(false);
      notification.success({
        message: `Recenzija ${isApproved ? 'odbijena' : 'odobrena'}`,
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška pri ažuriranju recenzije',
        placement: 'bottomRight',
      });
    }
  };

  const handleDelete = async () => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/evaluations-b2b/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);

      history.push('/admin/reviews');

      notification.success({
        message: `Recenzija izbrisana`,
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška pri brisanju recenzije',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table table-users'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div>
          <Button
            className='contact-btn'
            onClick={() => {
              history.push('/admin/reviews');
            }}
            type='primary'
          >
            <i class='ri-file-list-line'></i>
            <span style={{ marginTop: '3px' }}>SVE RECENZIJE</span>
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Form
            form={form}
            initialValues={initialForm}
            onFinish={async (values) => handleForm(values)}
            name='recenzija'
          >
            <div>
              <Form.Item name='productTitle' label='Artikal' className='contact-item'>
                <Input />
              </Form.Item>

              <Form.Item name='code' label='Šifra' className='contact-item'>
                <Input />
              </Form.Item>

              <Form.Item name='firstName' label='Ime' className='contact-item'>
                <Input />
              </Form.Item>

              <Form.Item name='email' label='Email' className='contact-item'>
                <Input />
              </Form.Item>

              <Form.Item name='value' label='Ocena' className='contact-item'>
                <Rate style={{ color: '#f95e00' }} disabled defaultValue={0} />
              </Form.Item>

              <div style={{ display: 'flex' }}>
                <Form.Item name='review' label='Recenzija' style={{ width: '500px' }}>
                  <TextArea rows={15} showCount />
                </Form.Item>

                <Form.Item name='answer' label='Odgovor' style={{ width: '500px' }}>
                  <TextArea rows={15} showCount />
                </Form.Item>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <button
                className='b2b-primary'
                style={{ backgroundColor: '#f95e00', border: '0px' }}
                type='button'
                onClick={() => handleApprove()}
              >
                <FolderOutlined style={{ marginRight: '5px' }} />
                {isApproved ? 'ODBIJ RECENZIJU' : 'ODOBRI RECENZIJU'}
              </button>

              <button
                className='b2b-primary'
                htmlType='submit'
                style={{ border: '0px', marginLeft: '10px', marginRight: '10px' }}
              >
                <DownloadOutlined style={{ marginRight: '5px' }} />
                SAČUVAJ
              </button>

              <Popconfirm
                title='Da li ste sigurni da želite da obrišete ovu recenziju?'
                onConfirm={() => handleDelete()}
                okText='OBRIŠI'
                cancelText='PONIŠTI'
              >
                <button className='b2b-primary' style={{ backgroundColor: 'red', border: '0px' }} type='button'>
                  <DeleteOutlined style={{ marginRight: '5px' }} />
                  OBRIŠI
                </button>
              </Popconfirm>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default EditReview;
