import React from 'react';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';

const ValueTable = ({ values, getColumnSearchProps, editValueHandler, deleteValueHandler, language, attrId }) => {
  const valueColumns = [
    {
      title: 'Vrednost',
      dataIndex: ['value', language.selected.code],
      ...getColumnSearchProps(['value', language.selected.code]),
    },
    {
      title: 'Opcije',
      width: 70,
      render: (text, record, index) => (
        <div className='table-actions'>
          <div
            className='lock'
            style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
            title='Izmeni vrednost'
            onClick={() => editValueHandler(attrId, record._id)}
          >
            <EditOutlined className='icon-unlock' />
            <EditFilled className='icon-lock' />
          </div>
          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <Popconfirm
              style={{ margin: '2px', padding: '4px' }}
              placement='left'
              title={`Ovo će izbrisati vrednost ${
                record.value[language.selected.code] ? record.value[language.selected.code].toLowerCase() : ''
              }`}
              onConfirm={() => deleteValueHandler(attrId, record._id)}
              okText='U redu'
              cancelText='Poništi'
            >
              <DeleteOutlined className='icon-unlock' title='Obriši vrednost' />
              <DeleteFilled className='icon-lock' title='Obriši vrednost' />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Table className='table-striped-rows' pagination={false} dataSource={values} columns={valueColumns} rowKey='_id' />
  );
};

export default ValueTable;
