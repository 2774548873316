import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { UserContext } from '../../App';
import { Form, notification, Input, Tooltip, Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FolderOpenOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import Axios from 'axios';

const initialForm = {
  firstName: null,
  lastName: null,
  workplace: null,
  email: null,
  phone: null,
};

function EditContact() {
  const history = useHistory();
  const { id } = useParams();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      const fetchContacts = async () => {
        try {
          const res = await Axios.get(`${SERVER_URL}/contact-data/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          });
          if (res && res.data) {
            form.setFieldsValue(res.data);
            console.log(res.data);
          }

        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Greška',
            description: 'Problem sa povlačenjem podataka.',
            placement: 'bottomRight',
          });
        }
      };
      fetchContacts();

    } else {
      console.log('Create Page');
    }
  }, [currentuser.data.token]);


  const handleForm = async (values) => {
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/contact-data/${id}` : `${SERVER_URL}/contact-data`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      console.log(values);

      notification.success({
        message: 'Success',
        description: id ? 'Kontakt ažuriran' : 'Kontakt kreiran',
        placement: 'bottomRight',
      });
      history.push('/admin/contacts');

    } catch (error) {
      notification.error({
        message: 'Greška',
        description: id ? 'Problem sa ažuriranjem kontakta' : 'Problem sa kreiranjem kontakta',
        placement: 'bottomRight',
      });
    }

  };

  return (
    <div className='table table-users'>
      <div>
        <Button className='contact-btn' onClick={() => {
          history.push('/admin/contacts');
        }} type='primary'>
          <i class='ri-file-list-line'></i>
          <span style={{ marginTop: '3px' }}>SVI KONTAKTI</span>
        </Button>
      </div>

      <br />
      <br />

      <div>
        <Form form={form} initialValues={initialForm} onFinish={async (values) => handleForm(values)} name='sliderForm'>
          <div className='form-content'>
            <FormItem
              className='contact-item'
              name='firstName'
              label='Ime'
              rules={[
                {
                  required: true,
                  message: 'Obavezno Polje',
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              className='contact-item'
              label='Prezime'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Obevezno polje',
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              className='contact-item'
              label='Naziv radnog mesta'
              name='workplace'
              rules={[
                {
                  required: true,
                  message: 'Obevezno polje',
                }
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              className='contact-item'
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Obavezno polje'
                }
              ]}
            >
              <Input type='email' />
            </FormItem>
            <FormItem
              className='contact-item'
              label='Telefon'
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Obavezno polje'
                }
              ]}
            >
              <Input type='number' />
            </FormItem>
          </div>
          <Form.Item style={{ marginTop: '30px' }}>
            <Button className='btn-contact' type='primary' htmlType='submit'>
              <FolderOpenOutlined style={{ marginRight: '8px' }} />
              {id ? 'SAČUVAJ' : 'KREIRAJ'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default EditContact;