import Slider from '../pages/slider/Slider';
import EditSlider from '../pages/slider/EditSlider';
import SliderGallery from '../pages/slider/SliderGallery';
import Contacts from '../pages/contacts/Contacts';
import EditContact from '../pages/contacts/EditContact';
import Reviews from '../pages/reviews/Reviews';
import EditReview from '../pages/reviews/EditReview';
import Articles from '../pages/home/Articles';
import Banners from '../pages/home/Banners';
import EditBanner from '../pages/home/EditBanner';
import BannerGallery from '../pages/home/BannerGallery';
import RetailStore from '../pages/retailStores/RetailStore';
import EditRetailStore from '../pages/retailStores/EditRetailStore';
import DashHomeCatalogs from '../pages/dashboardHome/DashHomeCatalogs';
import DashHomeBanners from '../pages/dashboardHome/DashHomeBanners';
import EditDashHomeBanners from '../pages/dashboardHome/EditDashHomeBanners';
import EditDashHomeCatalogs from '../pages/dashboardHome/EditDashHomeCatalogs';
import DashboardHomeGallery from '../pages/dashboardHome/DashboardHomeGallery';
import DashboardHomeBannerGallery from '../pages/dashboardHome/DashbboardHomeBannerGallery';

const routes = [
  {
    label: 'SLAJDER',
    icon: 'ri-window-fill',
    children: [
      {
        label: 'SLAJDER',
        path: '/admin/slider',
        component: Slider,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Ažuriraj Slajder',
        path: '/admin/edit-slider/:id',
        component: EditSlider,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Novi Slajder',
        path: '/admin/new-slider',
        component: EditSlider,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Galerija',
        path: '/admin/slider-gallery',
        component: SliderGallery,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'KONTAKTI',
    icon: 'ri-phone-fill',
    children: [
      {
        label: 'KONTAKTI',
        path: '/admin/contacts',
        component: Contacts,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi Kontakt',
        path: '/admin/new-contact',
        component: EditContact,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Novi Kontakt',
        path: '/admin/edit-contact/:id',
        component: EditContact,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'MP OBJEKTI',
    icon: 'ri-home-fill',
    children: [
      {
        label: 'MP OBJEKTI',
        path: '/admin/retail-stores',
        component: RetailStore,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi Objekat',
        path: '/admin/new-retail-store',
        component: EditRetailStore,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni Objekat',
        path: '/admin/edit-retail-store/:id',
        component: EditRetailStore,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'RECENZIJE',
    icon: 'ri-bookmark-3-line',
    children: [
      {
        label: 'RECENZIJE',
        path: '/admin/reviews',
        component: Reviews,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit review',
        path: '/admin/edit-review/:id',
        component: EditReview,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'HOME',
    icon: 'ri-home-fill',
    children: [
      {
        label: 'Artikli',
        path: '/admin/home/articles',
        component: Articles,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Baneri',
        path: '/admin/home/banners',
        component: Banners,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi Baneri',
        path: '/admin/home/new-banner',
        component: EditBanner,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Banera',
        path: '/admin/home/edit-banner/:id',
        component: EditBanner,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Baner galerija',
        path: '/admin/banner-gallery',
        component: BannerGallery,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'DASHBOARD HOME',
    icon: 'ri-home-fill',
    children: [
      {
        label: 'Baneri',
        path: '/admin/dashboard-home/banners',
        component: DashHomeBanners,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Katalozi',
        path: '/admin/dashboard-home/catalogs',
        component: DashHomeCatalogs,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Dash home new catalogue',
        path: '/admin/dashboard-home/catalogs-new',
        component: EditDashHomeCatalogs,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Dash home edit catalogs',
        path: '/admin/dashboard-home/catalogs/:id',
        component: EditDashHomeCatalogs,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Dash home new banner',
        path: '/admin/dashboard-home/banners-new',
        component: EditDashHomeBanners,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Dash home edit banners',
        path: '/admin/dashboard-home/banners/:id',
        component: EditDashHomeBanners,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Katalog galerija',
        path: '/admin/dashboard-home/catalogs-gallery',
        component: DashboardHomeGallery,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Baner galerija',
        path: '/admin/dashboard-home/banners-gallery',
        component: DashboardHomeBannerGallery,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
];

export default routes;
