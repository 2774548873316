import React, { useContext, useEffect } from 'react';
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import { LoadingOutlined } from "@ant-design/icons";
import Table from "../../components/tables/RefundTable";
import moment from "moment";

const TABLE_COLUMN_KEYS = ['_id', '__v', 'refund', 'status', 'userId', 'orderId', 'orderNumber', 'user', 'reclamation', 'createdAt', 'dateAccepted', 'dateResolve', 'currentAccount', 'updatedAt'];

const Reclamations = (props) => {
  const currentuser = useContext(UserContext);
  const [reclamations, fetchReclamations] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchReclamations(`${SERVER_URL}/reclamations`, []);
  }, [fetchReclamations]);

  let columnKeys;
  if (reclamations.data.items && reclamations.data.items.length > 0) {
    const keys = Object.keys(reclamations.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'orderNumber');
    columnKeys.splice(2, 0, 'status');
    columnKeys.splice(3, 0, 'user');

  }
  let tableData = [];
  if (reclamations.data.items && reclamations.data.items.length > 0) {
    tableData = reclamations.data.items.map((item) => {
      item.dateSubmitted = moment(item.dateSubmitted).format("DD.MM.YYYY");
      item.orderNumber = item.orderId ? item.orderId.orderNumber : '';
      item.user = item.userId ? item.userId.firstName + ' ' + item.userId.lastName : '';
      return item;
    });
  }
  return (
    <>
      <div className='iconLabelContainer'>
        <i className='ri-file-list-2-line'></i>
        <div className='icon-label'>VP REKLAMACIJE</div>
      </div>
      <div style={{ padding: "8px" }}>
        <div style={{ textAlign: "center" }}>
          {reclamations.isLoading && <LoadingOutlined spin style={{ fontSize: "3rem", marginTop: "5rem" }} />}
          {!reclamations.isLoading && reclamations.data.items && reclamations.data.items.length > 0 && (
            <Table data={tableData} columnKeys={columnKeys} title="Reclamation" editPath="/admin/reclamations/" />
          )}
          {!reclamations.isLoading && reclamations.data.items && reclamations.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
        </div>
      </div>
    </>
  );
};

export default Reclamations;
