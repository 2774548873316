import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CompanyTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'deliveryAddress',
  'commercialist',
  'termsOfPayment',
  'contactPerson',
  'discount',
  'createdAt',
  'updatedAt',
];

const Companies = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/companies`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/companies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Kompanija je izbrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/companies');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  const handleImportFile = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('excelFile', file);
    const response = await Axios.post(`${SERVER_URL}/import-companies`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: response.data.message,
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  return (
    <div className='table' style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/new-company'>
          <button className='b2b-primary'>Dodaj kompaniju</button>
        </Link>
        <Button
          className='importbutton'
          disabled={!currentuser.data.role.includes('admin')}
          style={{ marginLeft: '10px' }}
          icon={<UploadOutlined />}
          onClick={() => document.getElementById('input-file').click()}
          type='button'
        >
          Uvoz podataka
        </Button>
        {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
      </div>
      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Company'
            editPath='/admin/edit-company/'
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Companies;
