import React, { useEffect, useState, useContext } from 'react';
import { notification, Form, Input, Button, Tooltip, Image } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const initialForm = {
  title: null,
  link: null,
  image: null,
};

const EditDashHomeCatalogs = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchCatalogue = async () => {
        try {
          const catalogueRes = await Axios.get(`${SERVER_URL}/dashboard-home-catalogs/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (catalogueRes && catalogueRes.data) {
            form.setFieldsValue(catalogueRes.data);
            setImage(catalogueRes.data.image);

            const selected = sessionStorage.getItem('selected_images_catalogue');
            if (selected && selected !== 'undefined' && selected !== 'null') {
              const selectedVal = JSON.parse(selected);
              form.setFieldValue('image', Array.isArray(selectedVal) ? selectedVal[0] : selectedVal);
              setImage(Array.isArray(selectedVal) ? selectedVal[0] : selectedVal);
            }
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Greška',
            description: 'Problem sa povlačenjem podataka.',
            placement: 'bottomRight',
          });
        }
      };

      fetchCatalogue();
    } else {
      if (sessionStorage.getItem('dash_catalogue_title')) {
        form.setFieldValue('title', sessionStorage.getItem('dash_catalogue_title'));
      }
      if (sessionStorage.getItem('dash_catalogue_link')) {
        form.setFieldValue('link', sessionStorage.getItem('dash_catalogue_link'));
      }

      const selected = sessionStorage.getItem('selected_images_catalogue');
      if (selected && selected !== 'undefined' && selected !== 'null') {
        const selectedVal = JSON.parse(selected);
        form.setFieldValue('image', Array.isArray(selectedVal) ? selectedVal[0] : selectedVal);
        setImage(Array.isArray(selectedVal) ? selectedVal[0] : selectedVal);
      }
    }
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    if (values.image) {
      values.image = values.image._id;
    }
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/dashboard-home-catalogs/${id}` : `${SERVER_URL}/dashboard-home-catalogs`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Uspeh',
        description: id ? 'Katalog ažuriran' : 'Katalog kreiran',
        placement: 'bottomRight',
      });

      sessionStorage.removeItem('dash_catalogue_title');
      sessionStorage.removeItem('dash_catalogue_link');
      sessionStorage.removeItem('selected_images_catalogue');
      history.push('/admin/dashboard-home/catalogs');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Greška',
        description: id ? 'Problem sa ažuriranjem kataloga' : 'Problem sa kreacijom kataloga',
        placement: 'bottomRight',
      });
    }
  };

  const handleAddImages = () => {
    sessionStorage.removeItem('selected_images_catalogue');
    sessionStorage.setItem('selected_images_catalogue', JSON.stringify(image));
    history.push(
      !id ? `/admin/dashboard-home/catalogs-gallery` : `/admin/dashboard-home/catalogs-gallery?dashHomeId=${id}`,
    );
  };

  return (
    <div className='table table-users'>
      <div>
        <Button
          onClick={() => {
            sessionStorage.removeItem('dash_catalogue_title');
            sessionStorage.removeItem('dash_catalogue_link');
            sessionStorage.removeItem('selected_images_catalogue');
            history.push(`/admin/dashboard-home/catalogs`);
          }}
          type='primary'
        >
          SVI KATALOZI
        </Button>
      </div>

      <br />
      <br />

      <div>
        <Form
          form={form}
          initialValues={initialForm}
          onFinish={async (values) => handleForm(values)}
          name='catalogueForm'
        >
          <Form.Item
            name='title'
            label='Naziv kategorije'
            rules={[
              {
                required: true,
                message: 'Obavezno polje',
              },
            ]}
          >
            <Input onChange={(e) => sessionStorage.setItem('dash_catalogue_title', e.target.value)} />
          </Form.Item>

          <Tooltip title='Na koji link ce da vodi slika'>
            <Form.Item name='link' label='Link'>
              <Input onChange={(e) => sessionStorage.setItem('dash_catalogue_link', e.target.value)} />
            </Form.Item>
          </Tooltip>

          <Form.Item name='image' label='Slika'>
            {image && <Image style={{ width: '200px', height: '200px' }} src={`${SERVER_URL}/${image.url}`} />}
            <div
              style={{
                width: '96px',
                height: '96px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '0.5px solid #7999bc',
                background: '#fff',
                borderRadius: '50%',
                cursor: 'pointer',
                margin: '0',
                color: '#A1A1A1',
              }}
              title='Dodaj sliku'
              onClick={handleAddImages}
            >
              <div>Izmeni sliku</div>
            </div>
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              <FolderOpenOutlined style={{ marginRight: '5px' }} />
              SAČUVAJ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditDashHomeCatalogs;
