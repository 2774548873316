import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Form } from 'antd';

const formInit = {
  value: {},
};

const ValueForm = ({ setEdit, attrId, language, onSubmit, value }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(language.selected.code);
  ['createdAt', 'updatedAt', '__v'].forEach((key) => delete value[key]);
  let initialValues = { ...formInit, ...value };

  useEffect(() => {
    form.resetFields();
  }, [value, form]);

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span style={{ padding: '2px 5px' }}>DODAVANJE / IZMENA VREDNOSTI</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, attrId: null, value: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values, attrId, !value.value)}
          form={form}
        >
          <h3 style={{ marginBottom: '8px' }}>{tab} language</h3>
          <Tabs onChange={(key) => setTab(key)} tabPosition='left' defaultActiveKey={language.default.code}>
            {language.list.map((lang) => (
              <Tabs.TabPane tab={lang.code} key={lang.code} forceRender>
                <Form.Item
                  label='Vrednost'
                  rules={[
                    {
                      required: lang.code === language.default.code ? true : false,
                      message: 'Molim Vas da unesete vrednost!',
                    },
                  ]}
                  name={['value', lang.code]}
                >
                  <Input />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Divider type='horizontal' />
          <div className='text-right'>
            <button className='b2b-primary' htmlType='submit'>
              Dodaj
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ValueForm;
