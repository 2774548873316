import React, { useEffect, useContext, useState } from 'react';
import { Form, notification, Input, Tooltip, Button, Image } from 'antd';
import { UserContext } from '../../App';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { FolderOpenOutlined } from '@ant-design/icons';

const initialForm = {
  title: null,
  link: null,
  gallery: [],
};

const Slider = () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const { id } = useParams();
  const mobile = location.search && location.search.includes('mobile=true') ? true : false;
  const [currentGallery, setCurrentGallery] = useState([]);

  useEffect(() => {
    if (id) {
      const fetchSlider = async () => {
        try {
          const sliderRes = await Axios.get(`${SERVER_URL}/slider/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (sliderRes && sliderRes.data) {
            form.setFieldsValue(sliderRes.data);
            setCurrentGallery(sliderRes.data.gallery);
            const selected = sessionStorage.getItem('selected_images_slider');
            if (selected && selected !== 'undefined') {
              const selectedArr = JSON.parse(selected);
              form.setFieldValue('gallery', selectedArr);
              setCurrentGallery(selectedArr);
            }
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Greška',
            description: 'Problem sa povlačenjem podataka.',
            placement: 'bottomRight',
          });
        }
      };

      fetchSlider();
    } else {
      const selected = sessionStorage.getItem('selected_images_slider');
      if (selected && selected !== 'undefined') {
        const selectedArr = JSON.parse(selected);
        form.setFieldValue('gallery', selectedArr);
        setCurrentGallery(selectedArr);
      }
    }
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/slider/${id}` : `${SERVER_URL}/sliders`;
      values.mobile = mobile;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Success',
        description: id ? 'Slajder ažuriran' : 'Slajder kreiran',
        placement: 'bottomRight',
      });

      history.push('/admin/slider');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Greška',
        description: id ? 'Problem sa ažuriranjem slajdera' : 'Problem sa kreacijom slajdera',
        placement: 'bottomRight',
      });
    }
  };

  const handleAddImages = () => {
    sessionStorage.removeItem('selected_images_slider');
    sessionStorage.setItem('selected_images_slider', JSON.stringify(currentGallery));
    // window.location.assign(!id ? '/admin/slider-gallery' : `/admin/slider-gallery?sliderId=${id}`);
    history.push(
      !id
        ? `/admin/slider-gallery${mobile ? '?mobile=true' : ''}`
        : `/admin/slider-gallery?sliderId=${id}${mobile ? '&mobile=true' : ''}`,
    );
  };

  return (
    <div className='table table-users'>
      <div>
        <Button
          onClick={() => {
            sessionStorage.removeItem('selected_images_slider');
            history.push(`/admin/slider`);
          }}
          type='primary'
        >
          SVI SLAJDERI
        </Button>
      </div>

      <br />
      <br />

      <div>
        <Form form={form} initialValues={initialForm} onFinish={async (values) => handleForm(values)} name='sliderForm'>
          <Form.Item
            name='title'
            label='Naziv'
            rules={[
              {
                required: true,
                message: 'Obavezno polje',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Tooltip title='Na koji link ce da vodi slika'>
            <Form.Item name='link' label='Link'>
              <Input />
            </Form.Item>
          </Tooltip>

          <Form.Item name='gallery' label='Galerija'>
            {currentGallery &&
              currentGallery.length > 0 &&
              currentGallery.map((image) => (
                <Image style={{ width: '200px', height: '200px' }} src={`${SERVER_URL}/${image.url}`} />
              ))}
            <div
              style={{
                width: '96px',
                height: '96px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '0.5px solid #7999bc',
                background: '#fff',
                borderRadius: '50%',
                cursor: 'pointer',
                margin: '0',
                color: '#A1A1A1',
              }}
              title='Dodaj sliku'
              onClick={handleAddImages}
            >
              <div>Dodaj sliku</div>
            </div>
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              <FolderOpenOutlined style={{ marginRight: '5px' }} />
              SAČUVAJ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Slider;
