import React, { useContext } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { UserContext } from '../App';

// const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundColor: '#ffff',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)',
};

const companyLogoStyle = {
  maxWidth: '150px',
  marginBottom: '100px',
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ ...backgroundStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Row gutter={[16, 16]}>
        <Col className='loginFirstCol' xs={24} sm={24} md={12} lg={12} xl={10}>
          <div className='login-content'>
            <img style={companyLogoStyle} className='login-logo' src='/himtex-logo-gray.svg' alt='Himtex Logo' />
            <div className='firstStepLogin'>
              <h2>PRIJAVA NA <span style={{ color: '#F95E00' }}>B2B</span> ADMIN DASHBOARD</h2>
              <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item name='email' rules={[{ required: true, message: 'Molim Vas da unesete vaš email!' }]}>
                  <Input placeholder='e-mail' className='orangeInput' />
                </Form.Item>

                <Form.Item name='password' rules={[{ required: true, message: 'Molim Vas da unesete vaš password!' }]}>
                  <Input.Password placeholder='lozinka' className='orangeInput' />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                  <Button type='primary' style={{ backgroundColor: '#f95e00', borderRadius: '5px', color: '#fff', fontSize: '16px' }} htmlType='submit'>
                    PRIJAVA
                  </Button>
                  <span
                    className='forgot-password-span'
                    type='link'
                  >
                    Zaboravljena lozinka?
                  </span>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col className='loginSecCol' xs={24} sm={24} md={12} lg={12} xl={14}>
          <div className='login-image'>
            <img className='login-image-img' src='/B2B-login.webp' alt='Himtex Login' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
