import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import ViewOrder from '../pages/orders/ViewOrders';
import Companies from '../pages/company/Company';
import EditCompany from '../pages/company/EditCompany';
import AddCompanyBranch from '../pages/company/AddCompanyBranch';
import Reclamations from '../pages/reclamations/Reclamations';
import Refunds from '../pages/refund/Refunds';
import EditReclamation from '../pages/reclamations/EditReclamation';
import EditRefund from '../pages/refund/EditRefund';

const routes = [
  {
    label: 'NOVE - MP',
    icon: 'ri-ball-pen-fill',
    children: [
      {
        label: 'Nove porudžbenice MP',
        path: '/admin/new-orders-mp',
        value: 'mp new',
        component: Orders,
        allowed: ['admin', 'commercialist'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-order/:orderId',
        value: 'mp',
        component: EditOrder,
        allowed: ['admin', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'View order',
        path: '/admin/view-order/:orderId',
        value: 'mp',
        component: ViewOrder,
        allowed: ['admin', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'ODRAĐENE - MP',
    icon: 'ri-money-dollar-box-line',
    children: [
      {
        label: 'Odrađene porudžbenice MP',
        path: '/admin/finished-orders-mp',
        value: 'mp done',
        component: Orders,
        allowed: ['admin', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'MP KUPCI',
    icon: 'ri-window-fill',
    children: [
      {
        label: 'Svi maloprodajni kupci',
        path: '/admin/mp-users',
        component: Users,
        value: 'mp',
        allowed: ['admin', 'commercialist'],
        showInMenu: true,
      },
      {
        label: 'Izmena korisnika',
        path: '/admin/edit-user/:userId',
        component: ViewUser,
        allowed: ['admin', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'FAKTURE',
  //   icon: 'ri-window-fill',
  //   children: [
  //     {
  //       label: 'Svi proizvodi',
  //       path: '/admin/data',
  //       value: 'mp',
  //       component: Data,
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Novi proizvod',
  //       path: '/admin/new-data',
  //       value: 'mp',
  //       component: EditData,
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Edit Data',
  //       path: '/admin/edit-data/:dataId',
  //       value: 'mp',
  //       component: EditData,
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Kategorije',
  //       path: '/admin/data-categories',
  //       value: 'mp',
  //       component: Categories,
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Add/Edit Category',
  //       path: '/admin/data-categories/:categoryId',
  //       value: 'mp',
  //       component: Categories,
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Atributi',
  //       path: '/admin/data-attributes',
  //       value: 'mp',
  //       component: Attributes,
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'REKLAMACIJE',
  //   icon: 'ri-ball-pen-fill',
  //   children: [
  //     {
  //       label: 'Sve reklamacije',
  //       path: '/admin/reclamations',
  //       component: Reclamations,
  //       value: 'mp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Edit order',
  //       path: '/admin/reclamations/:reclamationId',
  //       component: EditReclamation,
  //       value: 'mp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'View order',
  //       path: '/admin/view-order/:orderId',
  //       component: ViewOrder,
  //       value: 'mp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'POVRATI',
  //   icon: 'ri-magic-line',
  //   children: [
  //     {
  //       label: 'Svi povrati',
  //       path: '/admin/refunds',
  //       component: Refunds,
  //       value: 'mp',
  //       allowed: ['admin', 'commercialist'],
  //       showInMenu: false,
  //     },

  //   ],
  // },
];

export default routes;
