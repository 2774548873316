import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const Orders = (props) => {
  const currentuser = useContext(UserContext);
  const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  const type = props.topBar.split(' ')[0] === 'vp' ? 'corporate' : 'private';
  const status = props.topBar.split(' ')[1] === 'new' ? 'On hold' : 'Processed';

  let filter = `?type=${type}&status=${status}`;

  // if (currentuser.data.role === 'commercialist') {
  //   filter = `?type=${type}&status=${status}&commercialist=${currentuser.data.firstName} ${currentuser.data.lastName}`;
  // }

  useEffect(() => {
    fetchOrders(`${SERVER_URL}/orders-by-type${filter}`, []);
  }, [fetchOrders, props.topBar]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/orders');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const newOrDone =
    props.topBar === 'vp new'
      ? 'NOVE - VP'
      : props.topBar === 'vp done'
      ? 'ODRAĐENE - VP'
      : props.topBar === 'mp new'
      ? 'NOVE - MP'
      : props.topBar === 'mp done'
      ? 'ODRAĐENE - MP'
      : '';

  const columnKeys = ['orderNumber', 'buyer', 'email', 'price', 'city', 'createdAt', 'updatedAt', 'status'];

  let tableData = [];
  if (orders.data && orders.data.items && orders.data.items.length > 0) {
    tableData = orders.data.items.map((item) => {
      if (item.user && item.user.email) {
        let price = 0;
        if (item.user.legalType === 'corporate') {
          price = (
            item.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) +
            +item.deliveryPrice +
            (item.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) +
              +item.deliveryPrice) *
              0.2
          ).toFixed(2);
        } else {
          price = (
            item.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) +
            +item.deliveryPrice
          ).toFixed(2);
        }
        item.buyer = `${item.user.firstName} ${item.user.lastName}`;
        item.email = item.user.email;
        // item.city = item.user?.municipality?.name;
        item.newDate = new Date(item.createdAt).toLocaleDateString('sr-Latn-RS', dateOptions);
        item.price = price;
        return item;
      } else {
        return item;
      }
    });
    if (currentuser.data.role === 'commercialist') {
      tableData = tableData.filter(
        (item) => item.user.commercialist === `${currentuser.data.firstName} ${currentuser.data.lastName}`,
      );
    }
  }

  return (
    <div className='table table-users'>
      <div style={{ textAlign: 'center' }}>
        {orders.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!orders.isLoading && orders.data && orders.data.items && orders.data.items.length > 0 && (
          <>
            <div className='ordersHeader'>
              <i className='ri-file-list-2-line' style={{ fontSize: '24px' }}></i>
              <span>{newOrDone} PORUDŽBINE</span>
            </div>

            <Table
              type={type}
              data={tableData}
              deleteHandler={deleteOrderHandler}
              columnKeys={columnKeys}
              title='Order'
              editPath='/admin/edit-order/'
              viewPath='/admin/view-order/'
            />
          </>
        )}
        {!orders.isLoading && orders.data && orders.data.items && orders.data.items.length === 0 && (
          <h2>NEMA PODATAKA</h2>
        )}
      </div>
    </div>
  );
};

export default Orders;
