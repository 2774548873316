import React, {useState, useEffect} from 'react';
import {Button, Card, Col, Form, Row, Select, Table, Typography, DatePicker, Input, Modal} from "antd";
import moment from "moment";
import {EditOutlined} from "@ant-design/icons";
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const formInit = {
	_id: null,
	status: '',
};

const formInitModal = {
	resolution: '',
	proposal: '',
	reclamationDeadline: '',
	reclamationResolvingWay: '',
	deadlineExtension: '',
	reclamationResolutionDate: '',
}

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 8 },
};

const ReclamationForm = ({ data, onSubmit, serverUrl, modalVisible, changeModalVisible, onSubmitModal }) => {
	const selectedRec = [data]
	const [form] = Form.useForm();
	const [formModal] = Form.useForm();
	const [currentIndex, setCurrentIndex] = useState(0);

	const deadlineTime = data && data.reclamation[currentIndex] && data.reclamation[currentIndex].reclamationDeadline ? new Date(data.reclamation[currentIndex].reclamationDeadline).getTime() : undefined;
	const resolutionDate = data && data.reclamation[currentIndex] && data.reclamation[currentIndex].reclamationResolutionDate ? new Date(data.reclamation[currentIndex].reclamationResolutionDate).getTime() : undefined;
	let initialValues = { ...formInit, ...data };
	let initialValuesModal = { ...formInitModal, ...{ ...data.reclamation[currentIndex], reclamationDeadline: deadlineTime ? moment(new Date(deadlineTime), 'YYYY-MM-DD') : '', reclamationResolutionDate: resolutionDate ? moment(new Date(resolutionDate), 'YYYY-MM-DD') : '' }}

	const onFinish = async (values) => {
		onSubmit(values);
	};
	const onFinishModal = async (values) => {
		onSubmitModal(values, currentIndex);
	}

	useEffect(() => formModal.resetFields(), [modalVisible]);

	let fullName = '';
	if (data.userId) {
		fullName += data.userId.firstName ? data.userId.firstName + ' ' : '';
		fullName += data.userId.lastName ? data.userId.lastName : '';
	}
	const reclamationStatus = ['Podneta', 'Primljena', 'Razrešena'];
	const reclamationResolution = ['Prihvaćena', 'Neprihvaćena'];
    const reclamationReason = ['Oštećeno pri transportu', 'Oštećeno fabrički'];
	const reclamationProposal = ['Zamena robe', 'Slanje na servis', 'Povrat novca'];

	const columns = [
		{
			key: '1',
			title: 'Šifra',
			dataIndex: 'code'
		},
		{
			key: '2',
			title: 'Naziv proizvoda',
			dataIndex: 'productName'
		},
		{
			key: '3',
			title: 'Isporučeno kom.',
			dataIndex: 'orderQuantity'
		},
		{
			key: '4',
			title: 'Reklamirano kom.',
			dataIndex: 'quantity'
		},
		{
			key: '5',
			title: 'Razlog reklamacije',
			dataIndex: 'reclamationReason'
		},
        {
			key: '6',
			title: 'Odluka',
			dataIndex: 'resolution'
		},
        {
			key: '7',
			title: 'Fotografija',
			dataIndex: 'productImage'
		},
		{
			key: '8',
			title: 'Akcija',
			render: (text, record, index) => (
				<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
					<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
						<a onClick={() => {setCurrentIndex(index); changeModalVisible();}}>
							<EditOutlined style={{ textDecoration: 'none', color: 'black'}} />
						</a>
					</div>
				</div>)
		},
	];
    const recColumns = [
		{
			key: '1',
			title: 'Broj reklamacije',
			dataIndex: 'reclamationNumber'
		},
		{
			key: '2',
			title: 'Porudzbina',
			render: (record) => {
				return(
					<div>
						{record.orderId.orderNumber}
					</div>
				)
			}
		},
		{
			key: '3',
			title: 'Status',
			dataIndex: 'status'
		},
		{
			key: '4',
			title: 'Kupac',
			render: () => {
				return(
					<div>
						{fullName}
					</div>
				)
			}
		},
		{
			key: '5',
			title: 'Komentar',
			dataIndex: 'comment'
		},
        {
			key: '6',
			title: 'Datum reklamacije',
			render: (record) => {
				return(

					<div>
						{moment(record.resolutionDate).format("DD.MM.YYYY")}
					</div>
				)
			}
		},
		{
			key: '7',
			title: 'Akcija',
			render: (text, record, index) => (
				<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px'}}>
					<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
						<a onClick={() => {setCurrentIndex(index); changeModalVisible();}}>
							<EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
						</a>
					</div>
				</div>)
		},
	];
	return (
		<div className='panel panel-body'>
			<div className='panel-body'>	
				<Form
					className='form-horizontal'
					layout={formItemLayout}
					initialValues={initialValues}
					onFinish={(values) => onFinish(values)}
					form={form}>
					<div className='iconLabelContainer'>
						<i className='ri-file-list-2-line'></i>
                    	<div className='icon-label'>REKLAMACIJA BR.{data.reclamationNumber}</div>
                	</div>
					<Table pagination={false} rowKey='_id' size="middle" style={{ margin: '0px 0px 40px 0px' }} columns={recColumns}  bordered  dataSource={selectedRec}/>
					<Row type='flex' gutter={16} style={{justifyContent: 'left'}}>
						<Col xs={24} md={8}>
							<div className='panel-body'>
								<Form.Item label='Status reklamacije' name='status'>
									<Select>
										{reclamationStatus.map((item, index) => (
											<Option key={index} value={item}>{item}</Option>
										))}
									</Select>
								</Form.Item>


							</div>
						</Col>
                        <Col>
                            <div className='text-right'>
								<Button className='reclSubmit' type='primary' htmlType='submit'>
									Izmeni
								</Button>
							</div>
						</Col>
						<Modal centered
							title={`Reklamacija - ${data.reclamation[currentIndex].productName}`}
							visible={modalVisible}
							cancelButtonProps={{ style: { display: 'none' } }}
							okButtonProps={{ style: { display: 'none' } }}
							onCancel={changeModalVisible}
							style={{top:'100px'}}
						>
							<Form
								onFinish={onFinishModal}
								form={formModal}
								initialValues={initialValuesModal}
								layout='vertical'>
								<Form.Item label='Odluka reklamacije' name='resolution'>
								<Select>
									{reclamationResolution.map((item, index) => (
										<Option key={index} value={item}>{item}</Option>
									))}
								</Select>
							    </Form.Item>
                                <Form.Item label='Razlog reklamacije' name='reason'>
								<Select>
									{reclamationReason.map((item, index) => (
										<Option key={index} value={item}>{item}</Option>
									))}
								</Select>
							    </Form.Item>
                                <Form.Item label='Ugovoren rok za rešenje reklamacije' name='reclamationDeadline'>
                                    <DatePicker placeholder="Izaberite datum"/>
                                </Form.Item>
                                <Form.Item label='Način rešavanja reklamacije' name='reclamationResolvingWay'>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label='Informacije o produžetku roka rešavanja reklamacije' name='deadlineExtension'>
                                    <TextArea rows={4}/>
                                </Form.Item>
                                <Form.Item label='Datum rešenja reklamacije' name='reclamationResolutionDate'>
                                    <DatePicker placeholder="Izaberite datum"/>
                                </Form.Item>
                                    <div className='text-right'>
                                        <Button style={{ background:'#e65800'}} type='primary' htmlType='submit'>
                                            Izmeni
                                        </Button>
                                    </div>
							</Form>
						</Modal>
					</Row>
					<div className='iconLabelContainer'>
						<i className='ri-file-list-2-line'></i>
						<div className='icon-label' id="productsReclamation">PROIZVODI NA REKLAMACIJI</div>
					</div>
					<Table size='middle' pagination={false} rowKey='_id' columns={columns} dataSource={data.reclamation} bordered/>
				</Form>
			</div>
		</div>
	)
}

export default ReclamationForm;
