import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import TopBarMenu from './TopBarMenu';
import NavMenuTablet from './NavMenuTablet';

const { Content } = Layout;

const AdminLayout = (props) => {
  // const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);

  // const handleSignOutSubmit = async (event) => {
  //   event.preventDefault();
  //   sessionStorage.removeItem('user');
  //   window.location.href = '/login';
  // };

  // const userMenu = (
  //   <Menu style={{ width: 120 }}>
  //     <Menu.Item>
  //       <Link to={`/admin/edit-user/${user.data ? user.data.id : ''}`}>My account</Link>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <span onClick={handleSignOutSubmit}>Log out</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <>
      <Layout
        className='oldLayout'
        style={{
          minHeight: '100vh',
        }}
      >
        <NavMenu
          topBar={props.topBar}
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          collapsedNav={collapsedNav}
        />
        <TopBarMenu topBar={props.topBar} collapsedNav={collapsedNav} setCollapsedNav={setCollapsedNav} setTopBar={props.setTopBar} />
        <Layout>
          <div className='layout-container'>
            <Button type='primary' className='btn-collapse' onClick={() => setCollapsedNav(!collapsedNav)}>
              {React.createElement(collapsedNav ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
            <Link to='/admin' className='logo-mobile'>
              <div className='logo'>
                {/* <img src='logoW.png' alt='Universal logo' /> */}
                <img src='himtex-logo.svg' alt='Universal logo' />
              </div>
            </Link>
          </div>

          <Content className='content-space'>
            <Routes topBar={props.topBar} />
          </Content>
        </Layout>
      </Layout>


      {/* TABLET MENU */}
      <Layout className='newLayout'>
        <NavMenuTablet
          setTopBar={props.setTopBar}
          topBar={props.topBar}
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          collapsedNav={collapsedNav}
        />

      </Layout>
    </>
  );
};

export default AdminLayout;
