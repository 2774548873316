import React, { useState, useEffect, useContext } from 'react';
import { notification, Tooltip, Button, Table, Popconfirm, Spin, Divider, Input, Image } from 'antd';
import { EditOutlined, DeleteOutlined, MenuOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const Banners = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [group1, setGroup1] = useState({ title: null, data: [] });
  const [group2, setGroup2] = useState({ title: null, data: [] });
  const [group3, setGroup3] = useState({ title: null, data: [] });
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        setWorking(true);

        const bannerRes = await Axios.get(`${SERVER_URL}/home-banners`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (bannerRes && bannerRes.data) {
          const gr1 = bannerRes.data.find((item) => item.group === 1);
          const gr2 = bannerRes.data.find((item) => item.group === 2);
          const gr3 = bannerRes.data.find((item) => item.group === 3);
          setGroup1({ title: gr1?.title, data: gr1?.items || [] });
          setGroup2({ title: gr2?.title, data: gr2?.items || [] });
          setGroup3({ title: gr3?.title, data: gr3?.items || [] });
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Greška',
          description: 'Problem sa povlačenjem podataka.',
          placement: 'bottomRight',
        });
      }
    };

    fetchBanners();
  }, [fetchTrigger, currentuser.data.token]);

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/home-banners-items/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);

      notification.success({
        message: 'Baner izbrisan.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem prilikom brisanja banera.',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: (
        <Tooltip title='Redosled ide od gore na dole i prevlacenjem se menja redosled'>
          <div style={{ display: 'flex' }}>
            SORT
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      ),
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'NAZIV KATEGORIJE',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'LINK',
      dataIndex: 'link',
      key: 'link',
      align: 'center',
    },
    {
      title: 'SLIKA',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (text, record) => (
        <Image
          src={`${SERVER_URL}${record?.image?.url}`}
          alt='Banner image'
          style={{ height: '50px', width: '50px' }}
        />
      ),
    },
    {
      title: 'AKCIJA',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Ažuriraj baner'>
                <EditOutlined
                  onClick={() => history.push(`/admin/home/edit-banner/${record._id}?group=${record.group}`)}
                />
              </Tooltip>
            </div>

            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Izbriši baner'>
                <Popconfirm
                  title='Da li ste sigurni da hoćete da izbrišete ovaj baner?'
                  onConfirm={async () => handleDelete(record._id)}
                  onCancel={() => console.log('Cancel')}
                  okText='Da'
                  okButtonProps={{ className: 'buttonCustom' }}
                  cancelText='Ne'
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  // group 1
  const onSortEnd1 = async ({ oldIndex, newIndex }) => {
    try {
      setWorking(true);
      if (oldIndex !== newIndex) {
        // UI sort
        const newData = arrayMoveImmutable(group1.data.slice(), oldIndex, newIndex).filter((el) => !!el);
        setGroup1({ ...group1, data: newData });

        // send changed indexes to server
        await Axios.patch(
          `${SERVER_URL}/home-banners/1`,
          { items: newData },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda banera',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer1 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd1} {...props} />
  );
  const DraggableBodyRow1 = ({ className, style, ...restProps }) => {
    const index = group1.data.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // group 2
  const onSortEnd2 = async ({ oldIndex, newIndex }) => {
    try {
      setWorking(true);
      if (oldIndex !== newIndex) {
        // UI sort
        const newData = arrayMoveImmutable(group2.data.slice(), oldIndex, newIndex).filter((el) => !!el);
        setGroup2({ ...group2, data: newData });

        // send changed indexes to server
        await Axios.patch(
          `${SERVER_URL}/home-banners/2`,
          { items: newData },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda banera',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer2 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd2} {...props} />
  );
  const DraggableBodyRow2 = ({ className, style, ...restProps }) => {
    const index = group2.data.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // group 3
  const onSortEnd3 = async ({ oldIndex, newIndex }) => {
    try {
      setWorking(true);
      if (oldIndex !== newIndex) {
        // UI sort
        const newData = arrayMoveImmutable(group3.data.slice(), oldIndex, newIndex).filter((el) => !!el);
        setGroup3({ ...group3, data: newData });

        // send changed indexes to server
        await Axios.patch(
          `${SERVER_URL}/home-banners/3`,
          { items: newData },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa čuvanjem redosleda banera',
        placement: 'bottomRight',
      });
      return;
    }
  };
  const DraggableContainer3 = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd3} {...props} />
  );
  const DraggableBodyRow3 = ({ className, style, ...restProps }) => {
    const index = group3.data.findIndex((x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const handleTitle = async (group) => {
    try {
      setWorking(true);
      const title = group === 1 ? group1.title : group === 2 ? group2.title : group3.title;

      await Axios.patch(
        `${SERVER_URL}/home-banners/${group}`,
        { title, group },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Uspešno promenjen naziv banera',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Problem sa ažuriranjem naslova',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table table-users'>
      <Spin spinning={working} tip='Učitavanje...'>
        <div>
          <h3>1.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Input
            value={group1.title}
            onChange={(e) => setGroup1({ ...group1, title: e.target.value })}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitle(1)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => history.push(`/admin/home/new-banner?group=1`)} type='primary'>
            DODAJ KATEGORIJU
          </Button>
        </div>

        <br />
        <br />

        {/* GROUP 1 */}
        <div>
          <Table
            size='middle'
            bordered
            dataSource={group1.data}
            columns={columns}
            rowKey={(record) => record._id}
            rowClassName={() => 'editable-row'}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
            components={{
              body: {
                wrapper: DraggableContainer1,
                row: DraggableBodyRow1,
              },
            }}
          />
        </div>

        <br></br>
        <br></br>
        <Divider />
        <br></br>
        <br></br>
        {/* GROUP 2 */}
        <div>
          <h3>2.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Input
            value={group2.title}
            onChange={(e) => setGroup2({ ...group2, title: e.target.value })}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitle(2)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => history.push(`/admin/home/new-banner?group=2`)} type='primary'>
            DODAJ BANER
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Table
            size='middle'
            bordered
            dataSource={group2.data}
            columns={columns}
            rowKey={(record) => record._id}
            rowClassName={() => 'editable-row'}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
            components={{
              body: {
                wrapper: DraggableContainer2,
                row: DraggableBodyRow2,
              },
            }}
          />
        </div>

        <br></br>
        <br></br>
        <Divider />
        <br></br>
        <br></br>
        {/* GROUP 3 */}
        <div>
          <h3>3.</h3>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Input
            value={group3.title}
            onChange={(e) => setGroup3({ ...group3, title: e.target.value })}
            style={{ border: '1px solid', marginRight: '10px', width: '400px' }}
          />
          <Button type='primary' onClick={async () => await handleTitle(3)}>
            IZMENI NASLOV
          </Button>
        </div>

        <div style={{ display: 'flex' }}>
          <Button onClick={() => history.push(`/admin/home/new-banner?group=3`)} type='primary'>
            DODAJ BANER
          </Button>
        </div>

        <br />
        <br />

        <div>
          <Table
            size='middle'
            bordered
            dataSource={group3.data}
            columns={columns}
            rowKey={(record) => record._id}
            rowClassName={() => 'editable-row'}
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
            components={{
              body: {
                wrapper: DraggableContainer3,
                row: DraggableBodyRow3,
              },
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Banners;
